import Popup from 'component/common/Popup'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux';
import { jobAddEmployee } from 'reduxToolkit/applicantSlice';
import ViewAllPopUp from './ViewAllPopUp';

const AddEmployeeDash = ({ empDetal, setOpenAddEmp }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [submit, setSubmit] = useState(false);
  const empName = empDetal?.jobFullName ? empDetal?.jobFullName : "User";
  const empEmail = empDetal?.email ? empDetal?.email : "";
  const emapDob = empDetal?.dob ? empDetal?.dob : "";
  const empContact = empDetal?.contactNo ? empDetal?.contactNo : "";

  const handleSubmit = () => {
    const employeesData = {
      user: {
        full_name: empName,
        contact_no: empContact,
        email: empEmail,
        date_of_birth: emapDob ? emapDob : "",
        role: "employee",
      },
    };
    dispatch(jobAddEmployee(employeesData, empDetal, setSubmit, setOpenAddEmp));
  }
  
  return (
    <>
    <Popup
      title={t('addNewEmp')}
      popupBox={() => setOpenAddEmp(false)}
      maxWidth="max-w-[45rem]"
      submitBtnText={t('add')}
      handleSubmit={handleSubmit}
      submitOnce={submit}
    >
      <div>
        <form onSubmit={handleSubmit}>
          <div className='grid grid-cols-2 gap-4 p-4'>
            <div className="flex flex-col space-y-2 text-black">
              <label htmlFor="fullName" className="text-[#313135]">
                {t("name")}
              </label>
              <input
                type="text"
                name="fullName"
                placeholder="Full Name"
                id="fullName"
                className="h-[3rem] w-full border border-[#E2E8F0] rounded p-[10px_14px_10px_10px] text-[#191919] capitalize"
                value={empName}
                readOnly
              // onChange={(e) => {
              //   Formik.handleChange(e);
              //   handleEmployee(e.target.value);
              // }}
              //   onBlur={(e) => {
              //     Formik.handleBlur(e);
              //     handleEmployee(e.target.value);
              //   }}
              //   onKeyDown={(e) => enterClick(e)}
              />
              {/* {Formik.errors.fullName && Formik.touched.fullName ? (
                    <p className="text-[red]">{Formik.errors.fullName}</p>
                   ) : null} */}
            </div>
            <div className="flex flex-col space-y-2 text-black">
              <label htmlFor="fullName" className="text-[#313135]">
                {t("email")}
              </label>
              <input
                type="text"
                name="fullName"
                placeholder="Full Name"
                id="fullName"
                className="h-[3rem] w-full border border-[#E2E8F0] rounded p-[10px_14px_10px_10px] text-[#191919]"
                value={empDetal?.email}
                readOnly
              // onChange={(e) => {
              //   Formik.handleChange(e);
              //   handleEmployee(e.target.value);
              // }}
              //   onBlur={(e) => {
              //     Formik.handleBlur(e);
              //     handleEmployee(e.target.value);
              //   }}
              //   onKeyDown={(e) => enterClick(e)}
              />
              {/* {Formik.errors.fullName && Formik.touched.fullName ? (
                    <p className="text-[red]">{Formik.errors.fullName}</p>
                   ) : null} */}
            </div>
            <div className="flex flex-col space-y-2 text-black">
              <label htmlFor="fullName" className="text-[#313135]">
                {t("dOB")}{t("optional")}
              </label>
              <input
                type="text"
                name="fullName"
                placeholder="Date of birth"
                id="fullName"
                className="h-[3rem] w-full border border-[#E2E8F0] rounded p-[10px_14px_10px_10px] text-[#191919]"
                value={empDetal?.dob}
                readOnly
              // onChange={(e) => {
              //   Formik.handleChange(e);
              //   handleEmployee(e.target.value);
              // }}
              //   onBlur={(e) => {
              //     Formik.handleBlur(e);
              //     handleEmployee(e.target.value);
              //   }}
              //   onKeyDown={(e) => enterClick(e)}
              />
              {/* {Formik.errors.fullName && Formik.touched.fullName ? (
                    <p className="text-[red]">{Formik.errors.fullName}</p>
                   ) : null} */}
            </div>
            <div className="flex flex-col space-y-2 text-black">
              <label htmlFor="fullName" className="text-[#313135]">
                {t("contactNo")}
              </label>
              <input
                type="text"
                name="fullName"
                placeholder="Full Name"
                id="fullName"
                className="h-[3rem] w-full border border-[#E2E8F0] rounded p-[10px_14px_10px_10px] text-[#191919]"
                value={empDetal?.contactNo}
                readOnly
              // onChange={(e) => {
              //   Formik.handleChange(e);
              //   handleEmployee(e.target.value);
              // }}
              //   onBlur={(e) => {
              //     Formik.handleBlur(e);
              //     handleEmployee(e.target.value);
              //   }}
              //   onKeyDown={(e) => enterClick(e)}
              />
              {/* {Formik.errors.fullName && Formik.touched.fullName ? (
                    <p className="text-[red]">{Formik.errors.fullName}</p>
                   ) : null} */}
            </div>
            <div className='flex gap-3'>
              <input
                className="h-6 w-6 self-center appearance-none rounded-lg text-[#191919] border
              accent-[#031B59!important] checked:bg-[#031B59!important]
              checked:border-primary checked:bg-primary checked:before:opacity-[0.16]
              checked:after:absolute checked:after:mt-[0.2rem] checked:after:ml-[0.5rem]
              checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem]
              checked:after:rotate-[45deg]
              checked:after:border-[0.15rem] checked:after:border-l-0
              checked:after:border-t-0 checked:after:border-solid checked:after:border-white
              checked:after:bg-transparent checked:after:content-['']"
                type='checkbox'
                checked={true}
              />
              <p className='text-[#031b59]'>{t('inviteMail')}</p>
            </div>
          </div>
        </form>
      </div>

    </Popup>
    </>
  )
}

export default AddEmployeeDash