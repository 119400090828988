import React from "react";
import LoginForm from "component/login";
import { Navigate } from "react-router-dom";


function Login() {
  const userData = localStorage.getItem("userLoginToken")
  ? JSON.parse(localStorage.getItem("userLoginToken"))
  : "";
  const tokenExpiryTime = localStorage.getItem("tokenExpiryTime");
   const isAuthenticated = () => {
    return (tokenExpiryTime && userData) !== null;
  };
  return (
    isAuthenticated() ? (<Navigate to="/opening/dashboard" />) :
    (<LoginForm /> )
  );
}

export default Login;
