import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { BsChevronRight } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ProfileAvtar from "component/common/ProfileAvtar";
import { useDispatch, useSelector } from "react-redux";
import WriteFeedBack from "./WriteFeedBack";
import StarRating from "./StarRatings";
import { useTranslation } from "react-i18next";
import { DeleteFeedback, getfeeback } from "reduxToolkit/JobSlice";
import LoaderComp from "component/loader/LoaderComp";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import { MdOutlineStarHalf } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";

const FeedbackPopup = ({ isOpen, onClose, job, setAgainData }) => {
  const dispatch = useDispatch();
  const [expandedFeedback, setExpandedFeedback] = useState(null);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [isRightPopupOpen, setIsRightPopupOpen] = useState(false);
  const [loder, setLoder] = useState(false);
  const { Getfeeback } = useSelector((state) => state.jobs);
  const [editData, setEditData] = useState(null);
  const [wait, setWait] = useState(false);
  const { t } = useTranslation();
  const menuRef = useRef();
  const getLocalId = JSON.parse(localStorage.getItem("userLoginToken"));

  const formatDateTime = (isoString) => {
    const date = new Date(isoString);

    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    const formattedTime = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return `${formattedDate}, ${formattedTime}`;
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getfeeback(job?.id, setLoder));
    }
  }, [isOpen]);

  const openRightPopup = () => {
    setIsRightPopupOpen(true);
  };
  const closeRightPopup = () => {
    setIsRightPopupOpen(false);
    setEditData(null);
  };
  const toggleFeedback = (index) => {
    setExpandedFeedback(expandedFeedback === index ? null : index);
  };
  const toggleDropdown = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handleEditFeed = (item) => {
    setEditData({ ...item });
    openRightPopup();
  };

  const handleSeeDoc = (file) => {
    window.open(file, "_blank");
  };

  const handleDelete = async (id) => {
    await dispatch(DeleteFeedback(id, setWait, setDropdownIndex));
    await dispatch(getfeeback(job?.id, setLoder));
  };

  useOnClickOutside(menuRef, () => {
    setDropdownIndex(null);
  });

  return (
    <>
      {isOpen && (
        <div
          className="w-full h-full flex items-center
      justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] "
        >
          <div
            className="max-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
          shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] min-w-[40%] transitionRight"
          >
            <div className="flex flex-col items-start p-5 gap-2">
              <div className="w-full flex items-center justify-between">
                <h2 className="font-Roboto text-2xl text-[#031B59] font-extrabold">
                  {t("feedBack")}
                </h2>
                <IoClose
                  onClick={onClose}
                  className="fill-[#686868] w-6 h-6 cursor-pointer"
                />
              </div>
              <div className="w-full bg-[#F2F6FF] flex items-center p-3 rounded-md">
                <button
                  onClick={openRightPopup}
                  className="flex justify-between w-full items-center"
                >
                  <h1>{t("writeFeed")}</h1>
                  <BsChevronRight className="h-3 w-4 stroke-1 cursor-pointer" />
                </button>
              </div>
              <div className="w-full mt-4 font-Roboto text-sm text-[#A1A1A1] font-extrabold">
                {Getfeeback?.length} feedback(s)
              </div>
              {loder ? (
                <div className="w-full justify-center">
                  <LoaderComp />
                </div>
              ) : (
                <div className="w-full h-[80vh] custom_scroll overflow-y-scroll mt-4 gap-10">
                  {Getfeeback?.map((item, index) => (
                    <div
                      key={item}
                      className="border-b border-gray-200 mb-2 pb-2 flex flex-col gap-4 p-3"
                    >
                      <div className="flex items-start gap-4">
                        <div className="">
                          {item.userImage ? (
                            <img
                              className="w-full h-full object-cover border-2 border-white rounded-full"
                              src={item.userImage}
                              alt="Profile Avatar"
                            />
                          ) : (
                            <ProfileAvtar
                              height="3rem"
                              width="3rem"
                              name={item.fullName ? item.fullName : "User"}
                            />
                          )}
                        </div>
                        <div className="flex flex-col w-full gap-[.5rem]">
                          <div className="flex justify-between items-center">
                            <p className="font-Roboto text-md text-[#031B59] font-extrabold">
                              {item.fullName !== (undefined || null)
                                ? item.fullName
                                : "User"}
                            </p>
                            <div className="relative flex items-center gap-2">
                              <p className="font-Roboto text-sm text-[#031B59] font-extrabold">
                                {item.rating}
                              </p>
                              <p>{item?.round !== "null" && item?.round}</p>
                              <div className="flex gap-1 items-center bg-[#F2F6FF] p-2 rounded-[0.25rem]">
                                <p>
                                  {" "}
                                  {item?.avgstars !== undefined || null || ""
                                    ? Number(item.avgstars).toFixed(1)
                                    : "N/A"}{" "}
                                </p>
                                <span className="text-yellow-500 text-2xl">
                                  {" "}
                                  <MdOutlineStarHalf />{" "}
                                </span>
                              </div>
                              {getLocalId?.id === item?.userId && (
                                <HiOutlineDotsVertical
                                  className="text-[#A1A1A1] cursor-pointer text-xl"
                                  onClick={() => toggleDropdown(index)}
                                />
                              )}
                              {dropdownIndex === index && (
                                <div
                                  ref={menuRef}
                                  className="absolute right-0 top-[2.5rem] w-[7rem] bg-white border
                                 border-gray-200 shadow-lg rounded-md z-20"
                                >
                                  <button
                                    className="w-full text-left px-4 py-2 text-[#191919] hover:bg-[#F2F6FF]"
                                    onClick={() => handleEditFeed(item)}
                                  >
                                    {t("edit")}
                                  </button>
                                  {wait ? (
                                    <div className="w-full text-left px-4 py-2 text-[#191919] hover:bg-[#F2F6FF]">
                                      {t("loading...")}
                                    </div>
                                  ) : (
                                    <button
                                      className="w-full text-left px-4 py-2 text-[#191919] hover:bg-[#F2F6FF]"
                                      onClick={() => handleDelete(item.id)}
                                    >
                                      {t("delete")}
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <p className="font-Roboto text-sm text-[#686868] font-[400] w-auto break-all ">
                            {item?.description}
                          </p>
                          <div className="ont-Roboto text-sm text-[#686868] font-[400] mb-1 ">
                            {formatDateTime(item?.created_at)}
                          </div>
                        </div>
                      </div>
                      {expandedFeedback === index && (
                        <div className="flex flex-col gap-2">
                          <div className="flex items-center justify-between">
                            <span className="font-Roboto text-sm text-[#686868] font-extrabold">
                              {t("techSkill")}
                            </span>
                            <StarRating
                              totalStars={5}
                              handleRating={() => {}}
                              rating={Math.floor(+item.technicalskills)}
                              skill={""}
                            />
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="font-Roboto text-sm text-[#686868] font-extrabold">
                              {t("softSkill")}
                            </span>
                            <StarRating
                              totalStars={5}
                              handleRating={() => {}}
                              rating={Math.floor(+item.soft_skills)}
                            />
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="font-Roboto text-sm text-[#686868] font-extrabold">
                              {t("expKnow")}
                            </span>
                            <StarRating
                              totalStars={5}
                              handleRating={() => {}}
                              rating={Math.floor(+item.experienceandknowledge)}
                            />
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="font-Roboto text-sm text-[#686868] font-extrabold">
                              {t("probSolve")}
                            </span>
                            <StarRating
                              totalStars={5}
                              handleRating={() => {}}
                              rating={Math.floor(
                                +item.problemsolvingandcriticalthinking
                              )}
                            />
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="font-Roboto text-sm text-[#686868] font-extrabold">
                              {t("projectManage")}
                            </span>
                            <StarRating
                              totalStars={5}
                              handleRating={() => {}}
                              rating={Math.floor(+item.projectmanagement)}
                            />
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="font-Roboto text-sm text-[#686868] font-extrabold">
                              {t("leaderInti")}
                            </span>
                            <StarRating
                              totalStars={5}
                              handleRating={() => {}}
                              rating={Math.floor(+item.leadershipandinitiative)}
                            />
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="font-Roboto text-sm text-[#686868] font-extrabold">
                              {t("culturFit")}
                            </span>
                            <StarRating
                              totalStars={5}
                              handleRating={() => {}}
                              rating={Math.floor(+item.culturalfit)}
                            />
                          </div>
                          <div className="flex items-center justify-between">
                            <span className="font-Roboto text-sm text-[#686868] font-extrabold">
                              {t("perfUnder")}
                            </span>
                            <StarRating
                              totalStars={5}
                              handleRating={() => {}}
                              rating={Math.floor(
                                +item.performanceunderpressure
                              )}
                            />
                          </div>
                        </div>
                      )}

                      <div className="flex justify-between items-center mt-2">
                        <div className="flex gap-2 items-center">
                          <p className="text-[1.3rem] text-[#031b59] font-bold">
                            {item.date}
                          </p>
                          {item?.image?.url && (
                            <div
                              className="flex justify-between items-center "
                              onClick={() => handleSeeDoc(item?.image?.url)}
                            >
                              <div>
                                <AiFillFileImage
                                  fontSize="30px"
                                  className="mx-2"
                                  color="skyBlue"
                                />
                              </div>
                              {t("File Name")}
                            </div>
                          )}
                        </div>
                        <button
                          onClick={() => toggleFeedback(index)}
                          className="font-Roboto text-sm text-[#031B59] font-extrabold"
                        >
                          {expandedFeedback === index ? "See less" : "See more"}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Right-Side Popup */}
      {isRightPopupOpen && (
        <WriteFeedBack
          setAgainData={setAgainData}
          jobId={job?.id}
          job={job}
          closeRightPopup={closeRightPopup}
          editData={editData}
          setEditData={setEditData}
        />
      )}
    </>
  );
};

export default FeedbackPopup;

FeedbackPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  job: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};
