import { createSlice } from "@reduxjs/toolkit";
import apiUrl from "api/apiUrl";
import Helper from "api/Helper";
import ToastServices from "ToastServices";

const initialState = {
    calenderEvents:{},
    calenderEventsByDate:{},
    calenderEventsById:null,
    isLoading:false,
    data: [],
};

const slice = createSlice({
    name: "calender",
    initialState,
    reducers: {
      addCalenderEvents: (state, { payload }) => {
        state.data = payload;
      },
        getCalenderEvents(state, action) {
            state.calenderEvents = action.payload;
        },
        getCalenderEventsById(state, action) {
          state.calenderEventsById = action.payload;
        },
        setLoading(state, action){
            state.isLoading = action.payload
        },
    },
});

export const { getAboutUs,setLoading, addCalenderEvents, getCalenderEventsById } = slice.actions;

export default slice.reducer;

export function postCalenderEvents(formDataToSend, handleClose, resetForm) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const path = `${apiUrl.events}`;
      const {response} = await Helper.post(formDataToSend,path,true);
      if (response?.status === 200 || response?.status === 201) {
        ToastServices.showToast({
          type: "success",
          message: "Added Successfully!",
          autoClose: 3000,
        });
        if (handleClose) {
          handleClose();
          resetForm();
        }
       
      } else {
        // In case response doesn't meet the success criteria
        throw new Error("Unexpected response");
      }
      } catch (error) {
        ToastServices.showToast({
          type: "error",
          message: "Error fetching Data!",
          autoClose: 3000,
        });
      }
  };
}

export function postClientEvents(formDataToSend, handleClose, resetForm) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const path = `${apiUrl.events}`;
      const {response} = await Helper.post(formDataToSend,path,true);
      if (response?.status === 200 || response?.status === 201) {
        ToastServices.showToast({
          type: "success",
          message: "Added Successfully!",
          autoClose: 3000,
        });
          if (handleClose) {
            resetForm();
            handleClose();
          }
          
        } else {
          // In case response doesn't meet the success criteria
          throw new Error("Unexpected response");
        }
      } catch (error) {
        ToastServices.showToast({
          type: "error",
          message: "Error fetching Data!",
          autoClose: 3000,
        });
      }
  };
}

export function postCandidateEvents(formDataToSend, handleClose, resetForm) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const path = `${apiUrl.events}`;
      const {response} = await Helper.post(formDataToSend,path,true);
      if (response?.status === 200 || response?.status === 201) {
        ToastServices.showToast({
          type: "success",
          message: "Added Successfully!",
          autoClose: 3000,
        });
          if (handleClose) {
            resetForm();
            handleClose();
          }
         
        } else {
          // In case response doesn't meet the success criteria
          throw new Error("Unexpected response");
        }
      } catch (error) {
        ToastServices.showToast({
          type: "error",
          message: "Error fetching Data!",
          autoClose: 3000,
        });
      }
  };
}

export function fetchCalenderEvents() {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const path = `${apiUrl.events}`;
      const {response} = await Helper.get(path);      
      if (response?.status === 200 || response?.status === 201) {
          // dispatch(getAboutUs(response.data));
          // dispatch(setLoading(false))
        }
      } catch (error) {
        ToastServices.showToast({
          type: "error",
          message: "Error fetching Data!",
          autoClose: 3000,
        });
      }
  };
}

export function fetchCalenderEventsById(id) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const path = `${apiUrl.events}/${id}`;
      const {response} = await Helper.get(path);
      
      if (response?.status === 200 || response?.status === 201 || response.success === true) {
          dispatch(getCalenderEventsById(response.event));
        }
        dispatch(setLoading(false))
      } catch (error) {
        dispatch(setLoading(false))
        ToastServices.showToast({
          type: "error",
          message: "Error fetching Data!",
          autoClose: 3000,
        });
      }
  };
}

export function fetchCalenderEventsByDate(date) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      // const path = `${apiUrl.events}`;
      // const {response} = await Helper.get(path);
      
      // if (response?.status === 200 || response?.status === 201) {
          // dispatch(getAboutUs(response.data));
          // dispatch(setLoading(false))
        // }
      } catch (error) {
        ToastServices.showToast({
          type: "error",
          message: "Error fetching Data!",
          autoClose: 3000,
        });
      }
  };
}
