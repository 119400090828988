import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import JobApplicantDetails from "./JobApplicantDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllEvent,
  getEventById,
  sendMailAPi,
  updateDropdownStatus,
} from "reduxToolkit/JobSlice";
import FeedbackPopup from "./FeedbackPopup";
import ScheduleInterviewPopup from "./ScheduleInterview";
import RejectPopUp from "./RejectPopUp";
import { useTranslation } from "react-i18next";
import OnBoardDate from "./OnBoardDate";
import ViewActivity from "./ViewActivity";

const JobApplicantAction = ({
  tabValue,
  job,
  jobIndex,
  setAgainData,
  againgetData,
  data,
}) => {
  const isUpper = data?.length > 7 && jobIndex >= data?.length - 3;
  const nestOpen =
    jobIndex >= 7
      ? "bottom-[10px]"
      : tabValue === 1
      ? "top-[4.8rem]"
      : "top-[9.7rem]";
  const { t } = useTranslation();
  const menuRef = useRef();
  const dispatch = useDispatch();
  const [popOpen, setPopOpen] = useState(false);
  const [nestedPopUp, setNestedPopUp] = useState(false);
  const [details, setDetails] = useState(false);
  const [rejectPop, setRejectPop] = useState(false);
  const [loder, setLoder] = useState(false);
  const [inter, setInter] = useState(false);
  const [personal, setPersonal] = useState("");
  const [statusString, setStatusString] = useState("");
  const [editId, setEditId] = useState();
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [schedulePopupOpen, setSchedulePopupOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [activityPop, setActivityPop] = useState(false);
  const [activityDetails, setActivityDetails] = useState(null);
  const [onBoardDateEdit, setOnBordDateEdit] = useState(null);
  const handleFeedbackModalClose = () => {
    setFeedbackModalOpen(false);
  };
  const { filterEvent } = useSelector((state) => state.jobs);

  useEffect(() => {
    if (editId) {
      dispatch(getEventById(editId));
    }
  }, [editId]);

  const handleUpdateOnDate = (jobEdit) => {
    setUpdateOpen(true);
    setOnBordDateEdit(jobEdit);
  };

  const handleAction = () => {
    setPopOpen(true);
  };
  const handleMove = async (val) => {
    setLoder(true);
    const jsonObj = {
      status: val,
      job_application_ids: job?.id,
    };
    await dispatch(updateDropdownStatus(jsonObj, setAgainData));
    setLoder(false);
  };

  const handleTdsDetails = (jobSelest) => {
    setPersonal(jobSelest);
    setDetails(true);
  };

  const handleEditInter = async (getId) => {
    setNestedPopUp(false);
    setEditId(getId);
    setInter(!inter);
    setSchedulePopupOpen(true);
  };

  const handleSeeResume = (file) => {
    window.open(file, "_blank");
  };

  const handleInterView = () => {
    setNestedPopUp(false);
    setInter(!inter);
  };

  const handleReject = (statusData) => {
    setStatusString(statusData);
    setRejectPop(true);
  };
  const handleSendMail = (job) => {
    dispatch(sendMailAPi(job?.id));
    setPopOpen(false);
  };

  const handleNestedPopUp = () => {
    setInter(false);
    setNestedPopUp(!nestedPopUp);
  };

  useOnClickOutside(menuRef, () => {
    setPopOpen(false);
    setNestedPopUp(false);
    setInter(false);
  });

  const setSchedulePopupClose = () => {
    setSchedulePopupOpen(false);
  };

  const handleReschedule = () => {
    setSchedulePopupOpen(true);
  };

  const handleAddOnboardDate = () => {
    setUpdateOpen(true);
    setOnBordDateEdit(null);
  };

  const handleActivity = (job) => {
    setActivityDetails(job);
    setActivityPop(!activityPop);
  };

  return (
    <div className="relative">
      {activityDetails && (
        <ViewActivity setActivityDetails={setActivityDetails} job={job} />
      )}
      {updateOpen && (
        <OnBoardDate
          setOnBoardPop={setUpdateOpen}
          editDate={onBoardDateEdit}
          jobDetails={job}
          setAgainData={setAgainData}
        />
      )}
      {rejectPop && (
        <RejectPopUp
          statusString={statusString}
          rejectDetails={job}
          setRejectPop={setRejectPop}
          setAgainData={setAgainData}
          setDetails={() => {}}
        />
      )}
      {details && (
        <JobApplicantDetails
          insideLoder={loder}
          handleMove={handleMove}
          tabValue={tabValue}
          personal={personal}
          setDetails={setDetails}
          underbutton={true}
          setAgainData={setAgainData}
        />
      )}
      <div>
        <FeedbackPopup
          setAgainData={setAgainData}
          job={job}
          isOpen={feedbackModalOpen}
          onClose={handleFeedbackModalClose}
        />
        <ScheduleInterviewPopup
          editId={editId}
          filterEvent={filterEvent}
          job={job}
          jobName={job?.jobFullName}
          jobTitle={job?.jobName}
          isOpen={schedulePopupOpen}
          setInter={setInter}
          setFilterData={setEditId}
          setIsopen={setSchedulePopupOpen}
          onClose={setSchedulePopupClose}
          againgetData={againgetData}
          setAgainData={setAgainData}
        />
        <div
          onClick={() => handleAction(job.status)}
          className="text-[#031b59] w-auto font-bold text-xl relative flex justify-between items-center"
        >
          <HiOutlineDotsHorizontal />
          {tabValue === 2 ? (
            job?.feedbackExist ? null : (
              <div className="w-[30px] bg-[#FF00000D] h-[30px] ml-3 rounded-full flex justify-center items-center">
                <div className="h-[10px] w-[10px] bg-red-600 rounded-full"></div>
              </div>
            )
          ) : null}
        </div>
        {popOpen && (
          <div className="w-full relative" ref={menuRef}>
            <div
              className={`absolute w-fit right-[10px] min-w-[12rem] h-fit rounded-[0.5rem] animate-popover
                ${isUpper ? "bottom-[13px]" : ""}
                   bg-white border p-1 z-10
                    shadow-[0px_4px_8px_0px_rgba(0,0,0,0.03)]`}
            >
              <div className="w-full">
                <div
                  className="w-full h-full rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                  onClick={() => handleSeeResume(job?.resumeUrl?.url)}
                >
                  {t("viewResume")}
                </div>
                <div
                  className="w-full h-full rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                  onClick={() => handleActivity(job)}
                >
                  {t("viewActivity")}
                </div>
                <div
                  className="w-full h-full rounded-lg pl-4 py-[7px]  hover:bg-[#F2F6FF] "
                  onClick={() => handleTdsDetails(job)}
                >
                  {t("viewDetail")}
                </div>
                {tabValue === 2 &&
                  (!job?.round ? (
                    <div
                      className="w-full flex flex-row h-full rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                      onClick={() => setSchedulePopupOpen(true)}
                    >
                      <p>{t("scheduleInterView")}</p>
                    </div>
                  ) : (
                    <>
                      <div className="">
                        <div
                          className="w-full flex justify-between
                           items-center h-full rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                          onClick={handleInterView}
                        >
                          <p>{t("plzSche")}</p>
                          {inter ? <BsChevronDown /> : <BsChevronRight />}
                        </div>
                      </div>
                    </>
                  ))}

                <div
                  className="w-full flex flex-row h-full rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                  onClick={() => {
                    setFeedbackModalOpen(true);
                  }}
                >
                  <p>{t("feedBack")}</p>
                </div>
                {tabValue === 3 ? (
                  <>
                    {job?.onBoardDate === null ? (
                      <div
                        className="w-full h-full flex justify-between items-center rounded-lg
                  pl-4 py-[7px] hover:bg-[#F2F6FF]"
                        onClick={handleAddOnboardDate}
                      >
                        {t("setOnBoardDate")}
                      </div>
                    ) : (
                      <div
                        className="w-full h-full flex justify-between items-center rounded-lg
                  pl-4 py-[7px] hover:bg-[#F2F6FF]"
                        onClick={() => handleUpdateOnDate(job)}
                      >
                        {t("editOnBoardDate")}
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    className="w-full h-full flex justify-between items-center rounded-lg
                  pl-4 py-[7px] hover:bg-[#F2F6FF]"
                    onClick={() => handleNestedPopUp()}
                  >
                    {t("moveTo")}
                    <BsChevronRight />
                  </div>
                )}
                <div
                  className="w-full h-full rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                  onClick={() => handleSendMail(job)}
                >
                  {t("resendMailStatus")}
                </div>
              </div>
            </div>
          </div>
        )}
        {nestedPopUp && (
          <div className="w-full relative">
            <div
              className={`absolute w-fit right-[201px] animate-popover
                ${nestOpen}
                   min-w-[10.825rem] rounded-[0.5rem]
                 bg-white border border-[#DEE4EB] p-1 z-10
                   shadow-[0px_4px_8px_0px_rgba(0,0,0,0.03)]`}
            >
              <div className="w-full" ref={menuRef}>
                {(tabValue === 1 || tabValue === 4 || tabValue === 5) && (
                  <div
                    className="w-full h-full rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                    onClick={() => {
                      handleMove("inprogress");
                    }}
                  >
                    {t("inprogress")}
                  </div>
                )}

                <div
                  className="w-full flex flex-row h-full rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                  onClick={() => {
                    handleMove("selected");
                  }}
                >
                  <p>{t("selected")}</p>
                </div>
                {tabValue === 4 ? null : (
                  <div
                    className="w-full h-full  items-center rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                    onClick={() => handleReject("onhold")}
                  >
                    {t("onHold")}
                  </div>
                )}
                {tabValue === 5 ? null : (
                  <div
                    className="w-full flex flex-row h-full rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                    onClick={() => handleReject("rejected")}
                  >
                    <p>{t("rejected")}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {inter && (
          <div
            className="absolute z-20 bg-white right-[12.5rem] w-[9rem] animate-popover border border-[#DEE4EB] rounded-[0.5rem] top-[7rem]"
            ref={menuRef}
          >
            <div
              className="w-full flex flex-row h-full rounded-lg hover:bg-[#F2F6FF] px-4 py-[7px]"
              onClick={handleReschedule}
            >
              <p>{t("Reschedule")}</p>
            </div>
            <div
              className="w-full flex flex-row h-full rounded-lg px-4 py-[7px]
                                hover:bg-[#F2F6FF] "
              onClick={() => handleEditInter(job?.eventId)}
            >
              <p>{t("Edit")}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobApplicantAction;
JobApplicantAction.propTypes = {
  tabValue: PropTypes.any,
  setAgainData: PropTypes.any,
  againgetData: PropTypes.any,
  job: PropTypes.any,
  dropdownRef: PropTypes.any,
  jobIndex: PropTypes.any,
};
