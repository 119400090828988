import React from 'react'
import DashboardAdmin from '../component/dashboard/DashboardAdmin'

const DashboardPage = () => {
  return (
    <div className='w-[100%] h-[98vh] overflow-auto'>
        <DashboardAdmin />
    </div>
  )
}

export default DashboardPage