/* eslint-disable max-len */
// eslint-disable-next-line max-len
import React from 'react';

const SelectedJActivity = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M20.7923 8.98235C20.6048 8.79481 20.4995 8.54046 20.4995 8.27524V4.99945C20.4995 4.44717 20.0517 3.99945 19.4995 3.99945H16.2237C15.9585 3.99945 15.7041 3.8941 15.5166 3.70656L13.2066 1.39656C12.816 1.00604 12.1829 1.00604 11.7923 1.39656L9.48235 3.70656C9.29481 3.8941 9.04046 3.99945 8.77524 3.99945H5.49945C4.94717 3.99945 4.49945 4.44717 4.49945 4.99945V8.27524C4.49945 8.54046 4.3941 8.79481 4.20656 8.98235L1.89656 11.2923C1.50604 11.6829 1.50604 12.316 1.89656 12.7066L4.20656 15.0166C4.3941 15.2041 4.49945 15.4585 4.49945 15.7237V18.9995C4.49945 19.5517 4.94717 19.9995 5.49945 19.9995H8.77524C9.04046 19.9995 9.29481 20.1048 9.48235 20.2923L11.7923 22.6023C12.1829 22.9929 12.816 22.9929 13.2066 22.6023L15.5166 20.2923C15.7041 20.1048 15.9585 19.9995 16.2237 19.9995H19.4995C20.0517 19.9995 20.4995 19.5517 20.4995 18.9995V15.7237C20.4995 15.4585 20.6048 15.2041 20.7923 15.0166L23.1023 12.7066C23.4929 12.316 23.4929 11.6829 23.1023 11.2923L20.7923 8.98235ZM18.7923 14.1866C18.6048 14.3741 18.4995 14.6285 18.4995 14.8937V16.9995C18.4995 17.5517 18.0517 17.9995 17.4995 17.9995H15.3937C15.1285 17.9995 14.8741 18.1048 14.6866 18.2923L13.2066 19.7723C12.816 20.1629 12.1829 20.1629 11.7923 19.7723L10.3123 18.2923C10.1248 18.1048 9.87046 17.9995 9.60524 17.9995H7.49945C6.94717 17.9995 6.49945 17.5517 6.49945 16.9995V14.8937C6.49945 14.6285 6.3941 14.3741 6.20656 14.1866L4.72656 12.7066C4.33604 12.316 4.33604 11.6829 4.72656 11.2923L6.20656 9.81235C6.3941 9.62481 6.49945 9.37046 6.49945 9.10524V6.99945C6.49945 6.44717 6.94717 5.99945 7.49945 5.99945H9.60524C9.87046 5.99945 10.1248 5.8941 10.3123 5.70656L11.7923 4.22656C12.1829 3.83604 12.816 3.83604 13.2066 4.22656L14.6866 5.70656C14.8741 5.8941 15.1285 5.99945 15.3937 5.99945H17.4995C18.0517 5.99945 18.4995 6.44717 18.4995 6.99945V9.10524C18.4995 9.37046 18.6048 9.62481 18.7923 9.81235L20.2723 11.2923C20.6629 11.6829 20.6629 12.316 20.2723 12.7066L18.7923 14.1866Z" fill="#031b59"/>
      <path d="M16.816 8.5H15.7336C15.4982 8.5 15.2744 8.60787 15.1359 8.79279L11.5081 13.5918L9.86497 11.4169C9.7265 11.2341 9.50496 11.1241 9.26726 11.1241H8.18491C8.03491 11.1241 7.94721 11.287 8.03491 11.4036L10.9104 15.2077C10.9783 15.2981 11.0679 15.3718 11.1716 15.4226C11.2754 15.4735 11.3903 15.5 11.5069 15.5C11.6236 15.5 11.7385 15.4735 11.8423 15.4226C11.946 15.3718 12.0356 15.2981 12.1035 15.2077L16.9637 8.77958C17.0537 8.6629 16.966 8.5 16.816 8.5Z" fill="#031b59"/>
    </svg>
  );
};

export default SelectedJActivity;
