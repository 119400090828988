import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { Trans } from "react-i18next";
import { Bounce, ToastContainer } from "react-toastify";
import store from "reduxToolkit/store";
import 'react-big-calendar/lib/css/react-big-calendar.css'

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Trans>
    <ToastContainer
      position= "top-right"
      autoClose= {3000}
      hideProgressBar= {false}
      closeOnClick= {true}
      pauseOnHover= {true}
      draggable= {true}
      progress= {undefined}
      theme= "colored"
      transition= {Bounce}
    />
    <Provider store={store}>
      <App />
    </Provider>
  </Trans>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
