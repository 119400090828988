import axios from "axios";
import ToastServices from "ToastServices";
import { unprotectedPaths } from "../utils/Constants";
import { getHost } from "./Config";
import apiUrl from "./apiUrl";

const baseURL = getHost();
const catchError = (error) => {
  if (error.message?.includes("401")) {
    ToastServices.showToast({
      message: "Unauthorised!",
      type: "error",
      autoClose: 3000,
    });
    setTimeout(() => {
      localStorage.removeItem("userLoginToken");
      localStorage.removeItem("tokenExpiryTime");
      window.location.assign("/login");
    }, 1500);
  }
};

const responseInterceptor = async (response, formData = false) => {
  const status = response.status;
  if (status === 401) {
    ToastServices.showToast({
      message: "Unauthorised!",
      type: "error",
      autoClose: 3000,
    });
    setTimeout(() => {
      localStorage.removeItem("userLoginToken");
      localStorage.removeItem("tokenExpiryTime");
      window.location.assign("/login");
    }, 1500);
  } else if (status === 404) {
    ToastServices.showToast({
      message: "Not Found !",
      autoClose: 3000,
    });
  } else if (status === 422) {
    ToastServices.showToast({
      message: "Unprocessable Entity !",
      type: "warning",
      autoClose: 3000,
    });
  } else if (status === 400) {
    ToastServices.showToast({
      message: "Bad Request!",
      type: "error",
      autoClose: 3000,
    });
  } else if (status === 500) {
    ToastServices.showToast({
      message: "Internal Server Error !",
      type: "error",
      autoClose: 3000,
    });
  }

  if (status === 200 || status === 201) {
    const result = formData ? response : response.data;
    return { response: result, status };
  } else {
    return { response: [], status };
  }
};

const requestInterceptor = () => {
  const token = JSON.parse(localStorage.getItem("userLoginToken"))?.token;
  const path = window.location.pathname.includes("/reset/")
    ? "/reset/"
    : window.location.pathname;
  if (!unprotectedPaths.includes(path)) {
    if (token) {
      return token;
    } else {
      ToastServices.showToast({
        message: "Unauthorised!",
        type: "error",
        autoClose: 3000,
      });
      setTimeout(() => {
        localStorage.removeItem("userLoginToken");
        localStorage.removeItem("tokenExpiryTime");
        window.location.assign("/login");
      }, 1500);
    }
  } else {
    return "";
  }
};

const Helper = {
  post: async (jsonObj = {}, path = "", formData = false) => {
    const token = requestInterceptor();
    const url = baseURL + path;

    try {
      const headers = {
        Authorization: token,
        "Access-Control-Allow-Origin": "*",
      };

      if (!formData) {
        headers["Content-Type"] = "application/json";
      }

      const response = await axios.post(
        url,
        formData ? jsonObj : JSON.stringify(jsonObj),
        { headers }
      );
      return responseInterceptor(response, formData);
    } catch (error) {
      catchError(error);
      throw new Error(
        `Request failed: ${error?.response?.data?.error || error?.message}`
      );
    }
  },

  put: async (jsonObj = {}, path = "", formData = false) => {
    const token = requestInterceptor();
    const url = baseURL + path;

    try {
      const headers = { Authorization: token };

      if (!formData) {
        headers["Content-Type"] = "application/json";
      }

      const response = await axios.put(
        url,
        formData ? jsonObj : JSON.stringify(jsonObj),
        { headers }
      );
      return responseInterceptor(response, formData);
    } catch (error) {
      catchError(error);
    }
  },

  patch: async (jsonObj = {}, path = "", formData = false) => {
    const token = requestInterceptor();
    const url = baseURL + path;

    try {
      const headers = { Authorization: token };

      if (!formData) {
        headers["Content-Type"] = "application/json";
      }

      const response = await axios.patch(
        url,
        formData ? jsonObj : JSON.stringify(jsonObj),
        { headers }
      );
      return responseInterceptor(response, formData);
    } catch (error) {
      catchError(error);
    }
  },

  delete: async (path = "") => {
    const token = requestInterceptor();
    const url = baseURL + path;

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };

      const response = await axios.delete(url, { headers });
      return responseInterceptor(response);
    } catch (error) {
      catchError(error);
    }
  },

  get: async (path = "", api = "") => {
    const token = requestInterceptor();
    const url = baseURL + path;

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };
      const response = await axios.get(url, { headers });
      if (
        api === apiUrl.showUserChatWithId ||
        api === apiUrl.projectSearch ||
        api === path
      ) {
        return response;
      } else {
        return responseInterceptor(response);
      }
    } catch (error) {
      catchError(error);
    }
  },

  getDownload: async (path = "") => {
    const token = requestInterceptor();
    const url = baseURL + path;

    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: token,
      };

      const response = await axios.get(url, { headers, responseType: "blob" });

      return response;
    } catch (error) {
      catchError(error);
    }
  },
};

export default Helper;
