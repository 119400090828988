import React, { useState } from "react";
import PropTypes from "prop-types";
import { RxCross1 } from "react-icons/rx";
import { AiFillFileImage } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import LoaderComp from "component/loader/LoaderComp";
import RejectPopUp from "./RejectPopUp";

const JobApplicantDetails = ({
  personal,
  setDetails,
  tabValue,
  handleMove,
  insideLoder,
  underbutton,
  setAgainData,
}) => {
  const { t } = useTranslation();
  const [statusString, setStatusString] = useState("");
  const [rejectPop, setRejectPop] = useState(false);

  const handleDownloadFile = (file) => {
    window.open(file, "_blank");
  };

  const handleReject = (statusData) => {
    setStatusString(statusData);
    setRejectPop(true);
  };

  return (
    <>
      <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] ">
        <div
          className="min-w-[40%] h-full bg-white flex-flex-col space-y-8
    shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight max-w-[40%] "
        >
          <div>
            <div className="flex justify-between items-center border-b-2 p-5">
              <h1 className="text-[1.50rem] text-[#031b59] font-bold">
                {t("applicantDetail")}
              </h1>
              <div onClick={() => setDetails(false)} className="border-2 p-2">
                <RxCross1 />
              </div>
            </div>
            <div className="h-[90vh] overflow-y-scroll custom_scroll">
              <div className="p-7 flex flex-col gap-8">
                <h1 className="text-[1.3rem] text-[#031b59] font-bold">
                  {t("contactInfo")}
                </h1>
                <div className="grid grid-cols-1 2xl:grid-cols-2 gap-4 xl:grid-cols-1">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="text-[1rem] font-normal"
                    >
                      {t("fullName")}
                    </label>
                    <p className="text-[1.2rem] font-semibold capitalize">
                      {personal?.jobFullName ? personal?.jobFullName : "---"}
                    </p>
                  </div>
                  <div className="max-w-[400px]">
                    <label htmlFor="email" className="text-[1rem] font-normal">
                      {t("email")}
                    </label>
                    <p className="text-[1.2rem] font-semibold break-words">
                      {personal?.email ? personal?.email : "--"}
                    </p>
                  </div>
                  <div>
                    <label
                      htmlFor="contact"
                      className="text-[1rem] font-normal"
                    >
                      {t("contact")}
                    </label>
                    <p className="text-[1.2rem] font-semibold">
                      {personal?.contactNo ? personal?.contactNo : "--"}
                    </p>
                  </div>
                  <div>
                    <label
                      htmlFor="country"
                      className="text-[1rem] font-normal"
                    >
                      {t("country")}
                    </label>
                    <p className="text-[1.2rem] font-semibold capitalize">
                      {personal?.country ? personal?.country : "--"}
                    </p>
                  </div>
                  <div>
                    <label htmlFor="state" className="text-[1rem] font-normal">
                      {t("state")}
                    </label>
                    <p className="text-[1.2rem] font-semibold capitalize">
                      {personal?.state ? personal?.state : "--"}
                    </p>
                  </div>
                  <div className="max-w-[400px]">
                    <label
                      htmlFor="linkedIn"
                      className="text-[1rem] font-normal"
                    >
                      {t("linkedIn")}
                    </label>
                    <p className="w-auto font-semibold break-words">
                      {personal?.linkedIn ? personal?.linkedIn : "--"}
                    </p>
                  </div>
                  <div className="max-w-[400px]">
                    <label
                      htmlFor="Portfolio"
                      className="text-[1rem] font-normal"
                    >
                      {t("portfolio")}
                    </label>
                    <p className="w-auto font-semibold break-words">
                      {personal?.portFolio ? personal?.portFolio : "--"}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="border px-7 p-3"
                onClick={() => handleDownloadFile(personal?.resumeUrl?.url)}
              >
                <h1 className="text-[1.3rem] text-[#031b59] font-bold">
                  {t("fileName")}
                </h1>
                <div className="flex gap-2 items-center">
                  <div>
                    <AiFillFileImage
                      fontSize="30px"
                      className="mx-2"
                      color="skyBlue"
                    />
                  </div>
                  <h1>{personal?.resumeUrl && "File Name"}</h1>
                </div>
              </div>
              <div className="px-7 p-3 flex flex-col gap-6">
                <h1 className="text-[1.3rem] text-[#031b59] font-bold">
                  {t("additionalInformation")}
                </h1>
                <div className="flex flex-col gap-6">
                  <div>
                    <label
                      htmlFor="Portfolio"
                      className="text-[1rem] font-normal"
                    >
                      {t("Workexperience")}
                    </label>
                    <p className="text-[1.2rem] font-semibold capitalize">
                      {personal?.experience ? personal?.experience : "--"}
                    </p>
                  </div>
                  <div>
                    <label
                      htmlFor="Portfolio"
                      className="text-[1rem] font-normal"
                    >
                      {t("currentCTC")}
                    </label>
                    <p className="text-[1.2rem] font-semibold uppercase">
                      {personal?.currentCTC ? personal?.currentCTC : "--"}
                    </p>
                  </div>
                  <div>
                    <label
                      htmlFor="Portfolio"
                      className="text-[1rem] font-normal"
                    >
                      {t("expextedSalary")}
                    </label>
                    <p className="text-[1.2rem] font-semibold uppercase">
                      {personal?.expectedSalary
                        ? personal?.expectedSalary
                        : "--"}
                    </p>
                  </div>
                  <div>
                    <label
                      htmlFor="Portfolio"
                      className="text-[1rem] font-normal"
                    >
                      {t("noticePeriodTime")}
                    </label>
                    <p className="text-[1.2rem] font-semibold capitalize">
                      {personal?.noticePeriod ? personal?.noticePeriod : "--"}
                    </p>
                  </div>
                  <div>
                    <label
                      htmlFor="Portfolio"
                      className="text-[1rem] font-normal"
                    >
                      {t("whatLocation")}
                    </label>
                    <p className="text-[1.2rem] font-semibold capitalize">
                      {personal?.preferredLocation
                        ? personal?.preferredLocation
                        : "--"}
                    </p>
                  </div>
                  <div>
                    <label
                      htmlFor="Portfolio"
                      className="text-[1rem] font-normal"
                    >
                      {t("negotiable")}
                    </label>
                    <p className="text-[1.2rem] font-semibold">
                      {personal?.negotiabled ? "Yes" : "No"}
                    </p>
                  </div>
                  <div>
                    <label
                      htmlFor="Portfolio"
                      className="text-[1rem] font-normal"
                    >
                      {t("areYouAnImmediateJoiner")}
                    </label>
                    <p className="text-[1.2rem] font-semibold">
                      {personal?.imidiateJoiner ? "Yes" : "No"}
                    </p>
                  </div>
                  <div>
                    <label
                      htmlFor="Portfolio"
                      className="text-[1rem] font-normal"
                    >
                      {t("howDidYouKnowAboutUs")}
                    </label>
                    <p className="text-[1.2rem] font-semibold capitalize">
                      {personal?.reference ? personal?.reference : "--"}
                    </p>
                  </div>
                  <hr />
                  {underbutton && (
                    <div className="flex gap-2 justify-end">
                      {tabValue !== 5 && (
                        <>
                          {insideLoder ? (
                            <LoaderComp />
                          ) : (
                            <button
                              className="bg-red-600 p-4 rounded-[30px] text-white"
                              onClick={() => handleReject("rejected")}
                            >
                              {t('moveReject')}
                            </button>
                          )}
                        </>
                      )}
                      {tabValue !== 2 && (
                        <>
                          {insideLoder ? (
                            <LoaderComp />
                          ) : (
                            <button
                              className="bg-green-500 p-4 rounded-[30px] text-white"
                              onClick={() => handleMove("inprogress")}
                            >
                              {t('moveInprogress')}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {rejectPop && (
        <RejectPopUp
          statusString={statusString}
          setDetails={setDetails}
          rejectDetails={personal}
          setRejectPop={setRejectPop}
          setAgainData={setAgainData}
        />
      )}
    </>
  );
};

JobApplicantDetails.propTypes = {
  personal: PropTypes.object.isRequired,
  setDetails: PropTypes.func.isRequired,
  tabValue: PropTypes.number.isRequired,
  handleMove: PropTypes.func.isRequired,
  insideLoder: PropTypes.bool.isRequired,
};

export default JobApplicantDetails;
