import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from "prop-types";
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import SideBarJobOpening from 'component/jobOpening/SideBarJobOpening';

export default function SidebarButton({
  to,
  isActive,
  icon: Icon,
  text,
}) {

  return (
    <NavLink
      to={to}
      className={`flex ${isActive ? "bg-white/10 before:rounded-tr-md" : ""}`}

    >
      <div
        title={text}
        className={`w-full h-[3rem] hover:bg-white/10 hover:scale-105 cursor-pointer`}
      >
        <div className={`flex h-14 text-base items-center`}>
          <div className={`basis-3/12 h-full w-full flex justify-end scale-125 items-center`}>
            {Icon && <Icon className="h-5 w-5" />}
          </div>
          <div className='flex justify-between items-center w-[70%]'>
            <p className={`flex basis-9/12 w-full h-full items-center pl-4 whitespace-nowrap`}>
              {text}
            </p>
          </div>
        </div>
      </div>
    </NavLink>
  );
}

SidebarButton.propTypes = {
  to: PropTypes.string,
  isActive: PropTypes.bool,
  icon: PropTypes.func,
  text: PropTypes.string,
  newChildren: PropTypes.any,
};
