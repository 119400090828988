import useDebounce from 'hooks/useDebounce';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getJobApplications } from 'reduxToolkit/JobSlice';

const UseFetchJobSection = ({
          currentPage,
          id,status,callback,againgetData,query,newId,permissionToFetch,clickCheck,itemsPagination,apiCheckLocation
}) => {
  const dispatch = useDispatch();
  let searchQuery = useDebounce(query, 500);

  useEffect(() => {
    const fetchJobsData = () => {
      // if (permissionToFetch.viewAll) {
        if (searchQuery){
          dispatch(getJobApplications(id, status,callback,searchQuery,newId,apiCheckLocation));
        }
        else {
          dispatch(getJobApplications(id, status,callback,"","",clickCheck,itemsPagination,currentPage,apiCheckLocation));
        }
      // }
    };
    fetchJobsData();
  }, [id,status,againgetData,searchQuery,clickCheck,currentPage,itemsPagination]);
};

export default UseFetchJobSection;
