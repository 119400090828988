import React, { Fragment, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from "react-i18next";
import { MdMoreHoriz } from "react-icons/md";

const DashboardCalanderTask = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  const tasks = [
    { name: "schedule", color: "#0ea5e9" },
    { name: "cadidateInterview", color: "#f59e0b" },
    { name: "clientInterview", color: "#10b981" },
  ];

  const meetings = [
    {
      title: "meeting Title",
      date: "Dec",
      dateNum: "25",
      startTime: "8.00 am",
      endTime: "10.00 pm",
    },
    {
      title: "candidate interview",
      date: "Dec",
      dateNum: "25",
      startTime: "8.00 am",
      endTime: "10.00 pm",
    },
    {
      title: "client interview",
      date: "Dec",
      dateNum: "25",
      startTime: "8.00 am",
      endTime: "10.00 pm",
    },
    {
      title: "meeting sync",
      dateNum: "25",
      date: "Dec",
      startTime: "8.00 am",
      endTime: "10.00 pm",
    },
    {
      title: "meeting sync",
      dateNum: "25",
      date: "Dec",
      startTime: "8.00 am",
      endTime: "10.00 pm",
    },
    {
      title: "meeting sync",
      dateNum: "25",
      date: "Dec",
      startTime: "8.00 am",
      endTime: "10.00 pm",
    },
    {
      title: "meeting sync",
      dateNum: "25",
      date: "Dec",
      startTime: "8.00 am",
      endTime: "10.00 pm",
    },
  ];

  return (
    <div className=" w-full  h-full flex items-start lg:grid lg:grid-cols-2 xl:grid-cols-3 sizeCalander gap-4 p-4 bg-white rounded-[1.25rem]">
      <div className=" w-full flex justify-center flex-col gap-2">
        <Calendar onChange={setDate} value={date} className={`w-full`} />
        <hr />
      </div>
      <div className="w-full">
        <h2 className="text-lg font-bold mt-1">{t("invites")}</h2>
        <div className="h-[31vh] overflow-y-auto custom_scroll mt-1">
          {meetings.map((meeting, index) => (
            <Fragment key={index}>
              <div className="flex justify-between">
                <div className="flex w-full items-center py-[0.49rem]">
                  <div
                    className={`h-[50px] w-[50px] flex justify-center items-center rounded-[0.3rem] ${
                      meeting.title?.includes("candidate")
                        ? "bg-[#f59e0b]"
                        : meeting?.title?.includes("client")
                        ? "bg-[#10b981]"
                        : meeting?.title?.includes("meeting")
                        ? "bg-[#0ea5e9]"
                        : null
                    }`}
                  >
                    <div className="text-center text-white">
                      {meeting.dateNum}
                      <p className="capitalize">{meeting.date}</p>
                    </div>
                  </div>
                  <div
                    className={`w-full h-full flex ${
                      meeting.title?.includes("candidate")
                        ? "bg-[#FEF6E7]"
                        : meeting?.title?.includes("client")
                        ? "bg-[#E8F8F3]"
                        : meeting?.title?.includes("meeting")
                        ? "bg-[#E7F6FD]"
                        : null
                    }`}
                  >
                    <div
                      className={`h-full w-full flex flex-col justify-center px-2 `}
                    >
                      <p className="capitalize font-medium text-[0.9rem]">
                        {meeting.title}
                      </p>
                      <div className="font-normal text-[#646978] text-[0.7rem] uppercase">
                        {meeting.startTime} - {meeting.endTime}
                      </div>
                    </div>
                    <div className="text-[1.2rem] pr-2">
                      <MdMoreHoriz />
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
        <div className="border boder-[rgba(222, 228, 235, 1)] mt-4"></div>
      </div>
      <div className="mt-1 w-full bg-white rounded-[1.25rem] shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] p-3">
        <h2 className="text-lg font-bold pb-2">{t("indicate")}</h2>
        <div>
          {tasks?.map((task, id) => (
            <div key={id} className="flex items-center gap-4 px-2 py-1 ">
              <div
                className={`w-[1rem] h-[1rem] rounded-[50%]`}
                style={{ backgroundColor: task?.color }}
              ></div>
              <div>
                <p className="font-normal">{t(task.name)}</p>
                <p>{task.time}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardCalanderTask;
