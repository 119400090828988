import useDebounce from "hooks/useDebounce";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobOpeningData } from "reduxToolkit/JobSlice";

const useFetchJobsData = ({
  permissionToFetch,
  itemsPerPage,
  query,
  currentPage,
  onFormClose,
  onConfirmationModalClose,
  handleCloseApplicantModal,
}) => {
  const dispatch = useDispatch();
  let searchQuery = useDebounce(query, 500);
  const { indiviualStatus } = useSelector((state) => state.jobs);
  useEffect(() => {
    const fetchJobsData = () => {
      // if (permissionToFetch.viewAll) {
        if (searchQuery)
          dispatch(fetchJobOpeningData(currentPage, itemsPerPage, searchQuery));
        else {
          dispatch(fetchJobOpeningData(currentPage, itemsPerPage));
        }
      // }
    };
    fetchJobsData();
  }, [searchQuery, currentPage, itemsPerPage, dispatch]);

  useEffect(() => {
    if (indiviualStatus === "success") {
      dispatch(fetchJobOpeningData());
      onFormClose();
      onConfirmationModalClose();
    }
  }, [indiviualStatus]);

  useEffect(() => {
    handleCloseApplicantModal();
  }, []);
};

export default useFetchJobsData;
