/* eslint-disable no-unused-vars */
import React, { useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import usePermissions, { mappedPermissionObj } from "hooks/usePermission";
import { AddButton } from "component/common/accessControlUi/Button";
import CustomTable from "component/common/table/CustomTable";
import { jobOpeningColum } from "./column";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useFetchJobsData from "./useFetchJobOpeningsData";
import JobsForm from "./JobsForm";
import ConfirmationModal from "component/common/table/ConfirmationModal";
import JobApplicants from "./JobApplicants";
import Search from "component/common/accessControlUi/Search";
import {
  JobOpeningStatusChange,
  getJobApplications,
  setIndividualJobOpening,
  setIndividualStatus,
  setJobID,
  setjobApplication,
} from "reduxToolkit/JobSlice";
import Paginate from "component/Employee/Paginate";
import { BiX } from "react-icons/bi";

const CurrentOpening = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isPending, startTransition] = useTransition();
  const [openApplications, setopenApplications] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { userPermission } = usePermissions(mappedPermissionObj.Jobopening);
  const [openForm, setOpenForm] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [editId, setEditId] = useState(null);
  const [status, setStatus] = useState("applied");
  const [itemsPagination, setItemPagination] = useState(10);
  const { jobOpenings, individualOpening, indiviualStatus, jobOpeningStatus } =
    useSelector((state) => state.jobs);
  let { data, totalPages } = jobOpenings;
  const [openConfirmation, setOpenConfimation] = useState({
    jobId: null,
    statusToChange: null,
  });
  let requestStatus = {
    open: "Open",
    close: "Close",
  };

  const handleOpenForm = () => {
    setEditId(null);
    setOpenForm(true);
  };

  const handleJobStatus = (jobId, status) => {
    setOpenConfimation({ jobId: jobId, statusToChange: status });
  };
  const handleEdit = (job) => {
    setOpenForm(true);
    setEditId(job?.id);
  };
  const handleApplications = (e, job) => {
    e.stopPropagation();
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("jobId", job.title);
    setSearchParams(newSearchParams);
    dispatch(getJobApplications(job.id, status, setopenApplications(true)));
    dispatch(setJobID(job));
  };
  const onFormClose = () => {
    dispatch(setIndividualStatus(""));
    setOpenForm(false);
    setEditId(null);
    if (Object.keys(individualOpening)?.length > 0) {
      dispatch(setIndividualJobOpening({}));
    }
  };

  const handleConfimationClose = () => {
    setOpenConfimation({
      jobId: null,
      statusToChange: null,
    });
  };

  const handleCloseApplicantModal = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    let jobIdPresent = newSearchParams.get("jobId");

    if (jobIdPresent) {
      newSearchParams.delete("jobId");
      setSearchParams(newSearchParams);
    }
    startTransition(() => {
      dispatch(setjobApplication([]));
      setopenApplications(false);
    });
  };

  const handleJobStatusConfirm = () => {
    dispatch(
      JobOpeningStatusChange(
        openConfirmation.jobId,
        openConfirmation.statusToChange,
        handleConfimationClose
      )
    );
  };

  useFetchJobsData({
    currentPage: currentPage,
    itemsPerPage: itemsPagination,
    query: searchQuery,
    permissionToFetch: userPermission,
    onFormClose: onFormClose,
    onConfirmationModalClose: handleConfimationClose,
    handleCloseApplicantModal,
  });

  return (
    <>
      {openForm && (
        <JobsForm
          setAddJobOpeningPop={onFormClose}
          id={editId}
          setEditId={setEditId}
          setOpenForm={setOpenForm}
        />
      )}
      {openApplications && (
        <div
          className="w-full h-full flex items-center
    justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)] "
        >
          <div
            className="min-w-[40%] h-full p-5 bg-white flex-flex-col space-y-8
        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight max-w-[80%]"
          >
            <JobApplicants setopenApplications={setopenApplications} />
          </div>
        </div>
      )}
      <div className="w-full h-[42px] bg-white flex justify-between py-[2.8rem] px-[2.3rem]">
        <div className="flex justify-center items-center">
          <h2 className="font-bold text-[1.5rem] text-[#031B59]">
            {t("currentOpening")}
          </h2>
        </div>
        <div className="flex items-center justify-center gap-4">
          <Search
            searchItem={searchQuery}
            setSearchItem={setSearchQuery}
            currentResource={userPermission}
          />
          <AddButton
            currentResource={mappedPermissionObj.Jobopening}
            title={t("createOpenings")}
            onClick={handleOpenForm}
            addButtonClass="h-[3.063rem] w-[9.625rem] p-2 border-[1.5px] border-[#E2E8F0]
                 text-[#031B59] rounded-full"
          />
        </div>
      </div>
      <div
        className="w-[96%] h-[calc(97vh-5.3rem)] bg-white space-y-4 flex flex-col p-5 pb-1
           rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
      >
        {openConfirmation && (
          <ConfirmationModal
            isPending={indiviualStatus === "loading"}
            isOpen={openConfirmation?.jobId}
            onClose={handleConfimationClose}
            onConfirm={handleJobStatusConfirm}
            title={
              openConfirmation.statusToChange === requestStatus.open
                ? t("activeJob")
                : t("deActiveJob")
            }
          />
        )}
        <div>
          {data?.length > 0 ? data?.length : 0} {t("listJob")}
        </div>

        {/* Custom table  */}
        <div className="w-full h-full overflow-y-auto custom_scroll">
          <CustomTable
            data={data}
            columns={jobOpeningColum}
            isLoading={jobOpeningStatus === "loading"}
            renderAction={(
              job,
              jobIndex,
              openDropdownId,
              dropdownRef,
              handleToggleDropdown
            ) => {
              return (
                <>
                  {job.status === "Close" ? (
                    <div className="flex gap-4">
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          className="sr-only peer"
                          checked={false}
                          onClick={() => {
                            handleJobStatus(job.id, requestStatus.open);
                          }}
                        />
                        <div
                          className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                         dark:peer-focus:ring-blue-800 dark:bg-gray-200 peer-checked:after:translate-x-full
                         rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#031b59] after:content-['']
                         after:absolute after:top-0.5 after:start-[2px] after:bg-grey after:border-gray-900 after:border
                         after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-900
                         peer-checked:bg-blue-600 flex flex-col"
                        >
                          <div className="absolute right-1 top-1">
                            <BiX />
                          </div>
                        </div>
                      </label>
                      <div className="w-[10rem] flex justify-center items-center text-[0.9rem]">
                        {t("disableText")} {job?.closeOpening}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="w-full relative"
                      onClick={() => handleToggleDropdown(job.id, jobIndex)}
                    >
                      <div className="text-[#031b59] font-bold text-xl">
                        <HiOutlineDotsHorizontal />
                      </div>
                      {openDropdownId === job?.id && (
                        <div
                          className={`absolute w-fit right-[10px] min-w-[12rem] rounded-[0.5rem] animate-popover
                          ${
                            data?.length >= 9 &&
                            (jobIndex === data?.length - 1 ||
                            jobIndex === data?.length - 2 ||
                            jobIndex === data?.length - 3
                              ? "bottom-[10px]"
                              : null)
                          }
                            bg-white border p-1 z-10
                          shadow-[0px_4px_8px_0px_rgba(0,0,0,0.03)]`}
                          ref={(el) => (dropdownRef.current[jobIndex] = el)}
                        >
                          <ul className="w-full">
                            <li
                              className="w-full h-full rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                              onClick={() => {
                                navigate("/opening/currentOpening/" + job?.id);
                              }}
                            >
                              {t("viewOpening")}
                            </li>
                            {/* {userPermission?.update && ( */}
                            <li
                              className="w-full flex flex-row h-full rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                              onClick={() => {
                                handleEdit(job);
                              }}
                            >
                              <p>{t("edit")}</p>
                            </li>
                            {/* // )} */}
                            {job.allApplicants ? (
                              <li
                                className="w-full h-full rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                                onClick={(e) => {
                                  handleApplications(e, job);
                                }}
                              >
                                {t("viewApplications")}
                              </li>
                            ) : null}
                            <li
                              className="w-full h-full text-[red] rounded-lg pl-4 py-[7px] hover:bg-[#F2F6FF]"
                              onClick={() =>
                                handleJobStatus(job.id, requestStatus.close)
                              }
                            >
                              {t("closeOpening")}
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </>
              );
            }}
          />
        </div>
        {/* pagination */}
        <div className="w-full flex items-end">
          <div className="w-full h-fit py-2 bg-white flex justify-between items-center">
            <div className="text-[#031B59] font-medium">
              {t("showing")} {currentPage} {t("of")} {totalPages}
            </div>
            <Paginate
              currentPage={+currentPage}
              initialPageCount={+totalPages}
              pageRangeDisplayed={5}
              setCurrentPage={setCurrentPage}
              setItemPerPage={setItemPagination}
              itemPerPage={itemsPagination}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentOpening;
