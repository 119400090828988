import React from "react";

export default function LoadSpinner() {
  return (
    <div className="flex-col gap-4 w-full flex items-center justify-center">
      <div
        className="w-8 h-8 border-[3px] text-blue-400 text-4xl animate-spin
                border-[#031B59] flex items-center justify-center border-t-[#F3F6FD]
                rounded-full"
      ></div>
    </div>
  );
}
