/* eslint-disable max-len */
// eslint-disable-next-line max-len
import React from 'react';

const RejectedActivity = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7012 2C7.18117 2 2.70117 6.48 2.70117 12C2.70117 17.52 7.18117 22 12.7012 22C18.2212 22 22.7012 17.52 22.7012 12C22.7012 6.48 18.2212 2 12.7012 2ZM12.7012 20C8.28117 20 4.70117 16.42 4.70117 12C4.70117 10.15 5.33117 8.45 6.39117 7.1L17.6012 18.31C16.2512 19.37 14.5512 20 12.7012 20ZM7.80117 5.69L19.0112 16.9C20.0712 15.55 20.7012 13.85 20.7012 12C20.7012 7.58 17.1212 4 12.7012 4C10.8512 4 9.15117 4.63 7.80117 5.69Z" fill="#031b59"/>
    </svg>
  );
};

export default RejectedActivity;
