import React from "react";
import { FaRegCalendar } from "react-icons/fa";
import PropTypes from "prop-types";

function DatePicker({
  name,
  value,
  handleChange,
  handleBlur = () => {},
  disabled = false,
  styles = "",
  min,
  max,
}) {
  return (
    <div className={`flex border outline-none ${styles} relative`}>
      <input
        className="w-full p-[10px] h-[46px] text-black relative rounded-[0.25rem] outline-none z-10 bg-transparent"
        type="date"
        id="date"
        value={value}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        min={min}
        max={max}
      />
      <FaRegCalendar className="text-sm mt-4 absolute right-0 mr-3" />
    </div>
  );
}

export default DatePicker;

DatePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  styles: PropTypes.string,
  min: PropTypes.any,
  max: PropTypes.any,
};
