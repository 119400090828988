import DatePicker from "component/common/DatePicker";
import LoaderComp from "component/loader/LoaderComp";
import { Form, FormikProvider, useFormik } from "formik";
import useDebounce from "hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { SlCloudUpload } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { postCalenderEvents } from "reduxToolkit/slices/calender";
import { formatTime } from "utils/date";
import * as Yup from "yup";
import { handleSearchUserForChat } from "redux/appThunk/Employee/chat";

const ScheduleEventPopUp = ({
  jobTitle,
  job,
  filterEvent,
  toggleAddEventModal,
  selectedOption,
}) => {
  const { t } = useTranslation();
  const [guestName, setGuestName] = useState("");
  const [formData, setFormData] = useState([]);
  const [formDataId, setFormDataId] = useState([]);
  const [guestTypeEmail, setGuestTypeEmail] = useState("");
  const [loder, setLoder] = useState(false);
  const { searchResult } = useSelector((state) => state.jobs);
  const formattedDate = filterEvent
    ? new Date(filterEvent.date).toISOString().split("T")[0]
    : "";
  const dispatch = useDispatch();
  const searchQuery = useDebounce(guestName, 300);

  const formattedEndTime = formatTime(filterEvent?.endTime);
  const formattedStartTime = formatTime(filterEvent?.startTime);
  const removeGuest = (guest) => {
    const filterNewData = formData?.filter((item) => item?.id !== guest?.id);
    setFormData(filterNewData);
  };

  const selectEmployee = (employee) => {
    setFormData([...formData, { id: employee.id, full_name: employee.name }]);
    setGuestName("");
  };

  const [initialValues, setInitalValue] = useState({
    title: jobTitle,
    date: "",
    startTime: "",
    endTime: "",
    description: "",
    resume: job?.resumeUrl?.name,
    Upload_Image: "",
  });

  const handleClose = () => {
    toggleAddEventModal();
  };

  const resetFormValues = () => {
    setInitalValue({
      title: jobTitle,
      date: "",
      startTime: "",
      endTime: "",
      description: "",
      resume: job?.resumeUrl?.name,
    });
    setFormData([]);
    setGuestName("");
  };

  useEffect(() => {
    if (filterEvent) {
      setFormData(filterEvent?.guest);
      setInitalValue({
        title: jobTitle,
        date: filterEvent ? formattedDate : "",
        startTime: filterEvent ? formattedStartTime : "",
        endTime: filterEvent ? formattedEndTime : "",
        description: filterEvent ? filterEvent?.desc : "",
        resume: job?.resumeUrl?.name,
      });
    } else {
      setInitalValue({
        title: jobTitle,
        date: "",
        startTime: "",
        endTime: "",
        description: "",
        resume: job?.resumeUrl?.name,
      });
    }
  }, [filterEvent]);

  const validationSchema = Yup.object({
    title: Yup.string().required("Please add Title"),
    date: Yup.string().required("Invalid date format"),
    startTime: Yup.string().required("Please add start time"),
    endTime: Yup.string()
      .required("Please add end time")
      .test(
        "is-greater",
        "End time should be greater than start time",
        function (value) {
          const { startTime } = this.parent;
          return startTime && value > startTime;
        }
      ),
  });

  useEffect(() => {
    if (guestName !== "") {
      dispatch(handleSearchUserForChat(searchQuery));
    }
  }, [searchQuery]);

  useEffect(() => {
    if (formData) {
      const formDataNew = formData?.map((item) => item.id);
      setFormDataId(formDataNew);
    }
  }, [formData]);

  useEffect(() => {
    if (filterEvent) {
      setGuestTypeEmail(
        filterEvent ? filterEvent?.guestEmails?.join(", ") : ""
      );
    }
  }, [filterEvent]);

  useEffect(() => {
    if (formData) {
      const formDataNew = formData?.map((item) => item.id);
      setFormDataId(formDataNew);
    }
  }, [formData]);

  const handleRemoveImage = () => {
    setFieldValue(`Upload_Image`, "");
    setInitalValue({ Upload_Image: "" });
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setFieldValue("Upload_Image", files[0]);
    }
  };

  const guestTypeEmailArray = guestTypeEmail
    .split(",")
    .map((email) => email.trim())
    .filter((email) => email.length > 0);

  const Formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const newForm = new FormData();
      if (filterEvent) {
        const json = {
          event: {
            event_type: "",
            title: values.title,
            date: values.date,
            start_time: values.startTime,
            end_time: values.endTime,
            description: values.description,
            guests_ids: formDataId,
            additional_emails: guestTypeEmailArray,
            event_documents_urls: values.Upload_Image,
          },
        };
        // needed when update
        // dispatch(updateEvent(filterEvent?.id, json, setLoder, setIsopen, setAgainData));
      } else {
        newForm.append("event[event_type]", selectedOption);
        newForm.append("event[title]", values.title);
        newForm.append("event[date]", values.date);
        newForm.append("event[start_time]", values.startTime);
        newForm.append("event[end_time]", values.endTime);
        newForm.append("event[description]", values.description);
        newForm.append("event[guests_ids][]", formDataId);
        newForm.append("event[event_documents]", values.Upload_Image);
        try {
          await dispatch(postCalenderEvents(newForm, handleClose, resetForm));
        } catch (error) {
          console.error("API Error:", error);
        }
      }
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
  } = Formik;

  return (
    <div className="p-4 pt-0">
      <FormikProvider value={Formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className="space-y-4 overflow-y-auto custom_scroll"
        >
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("title")}
            </label>
            <input
              type="text"
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("title")}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {errors.title && touched.title && (
              <p className="text-[red] w-80">{errors.title}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("date")}
            </label>
            <DatePicker
              name="date"
              value={values.date}
              handleChange={handleChange}
              onBlur={handleBlur}
              min={new Date().toISOString().split("T")[0]}
              className="h-[2.625rem] w-full"
            />
            {errors.date && touched.date && (
              <p className="text-[red] w-80">{errors.date}</p>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("startTime")}
              </label>
              <input
                name="startTime"
                type="time"
                value={values.startTime}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
              {errors.startTime && touched.startTime && (
                <p className="text-[red] w-80">{errors.startTime}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("endTime")}
              </label>
              <input
                name="endTime"
                type="time"
                value={values.endTime}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
              {errors.endTime && touched.endTime && (
                <p className="text-[red] w-80">{errors.endTime}</p>
              )}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("desc")}
            </label>
            <textarea
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={4}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {errors.description && touched.description && (
              <p className="text-[red] w-80">{errors.description}</p>
            )}
          </div>
          <div className="w-full">
            <label className="block text-[#313135]">{t("addGuest")}</label>
            <div className="w-full border">
              <div className="w-full flex gap-2">
                {formData?.map((guest, index) => (
                  <div
                    key={index}
                    className="flex items-center w-fit gap-3 bg-[#031b59]
                         text-white p-1 rounded-md"
                  >
                    <span className="">{guest?.full_name}</span>
                    <AiOutlineClose
                      className="cursor-pointer"
                      onClick={() => removeGuest(guest)}
                    />
                  </div>
                ))}
                <input
                  value={guestName}
                  className=" flex-1 p-2"
                  type="text"
                  placeholder="Add guest"
                  onChange={(e) => setGuestName(e.target.value)}
                />
              </div>
            </div>
            {guestName !== "" && (
              <div
                className="absolute flex justify-start flex-col
      w-full max-w-[36rem] bg-white gap-3
      shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-[8px] rounded-md z-10 overflow-y-auto max-h-80"
              >
                {searchResult.length > 0 ? (
                  searchResult.map((employee) => (
                    <div
                      key={employee.id}
                      onClick={(e) => selectEmployee(employee, e)}
                      className="flex items-center
                           text-black h-[3rem] px-2 cursor-pointer hover:bg-gray-100"
                    >
                      <img
                        src={`https://ui-avatars.com/api/?name=${employee.name}&background=random`}
                        alt="Profile Avatar"
                        className="w-8 h-8 rounded-full mr-2"
                      />
                      <p>{employee.name}</p>
                    </div>
                  ))
                ) : (
                  <div> {t("noDataFound")}</div>
                )}
              </div>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("document")}
            </label>
            {!values.Upload_Image ? (
              <div className="flex flex-col border border-gray-300 rounded-md p-4 items-center mt-1 cursor-pointer">
                <label
                  htmlFor="upload"
                  className="flex flex-col items-center cursor-pointer"
                >
                  <SlCloudUpload />
                  <p className="font-bold">{t("dragFile")}</p>
                </label>
                <input
                  id="upload"
                  type="file"
                  name="Upload_Image"
                  onChange={handleImageChange}
                  onBlur={handleBlur}
                  className="hidden"
                />
              </div>
            ) : (
              <div className="flex justify-between items-center mt-1 border border-gray-300 rounded-md p-4">
                <p className="text-sm">{values.Upload_Image.name}</p>
                <button
                  type="button"
                  onClick={handleRemoveImage}
                  className="text-red-500"
                >
                  {t("remove")}
                </button>
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="mt-4 px-9 py-3 rounded-[2.5rem] bg-[#031B59] text-white  w-fit"
            >
              {loder ? <LoaderComp /> : t("schedule")}
            </button>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default ScheduleEventPopUp;
