import { RxCross1 } from "react-icons/rx";

const Chips = ({ item, handleRemove }) => {
  return (
    <div className="bg-[#031b59] p-1 rounded-[30px] text-white w-fit flex gap-2 items-center">
      <h1 className="capitalize text-sm pl-2 break-all">{item?.name}</h1>
      <div className="cursor-pointer" data-testid="removeId" onClick={() => handleRemove(item)}>
        <RxCross1 />
      </div>
    </div>
  );
};

export default Chips;
