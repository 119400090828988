import React from 'react';
import PropTypes from 'prop-types';

const StarRating = ({ totalStars,handleRating = (() => {}),rating,skill }) => {

  return (
    <div
      className="star-rating"
    >
      <svg width="0" height="0">
        <defs>
          <linearGradient id="half-star">
            <stop offset="50%" stopColor="#ffc107" />
            <stop offset="50%" stopColor="#e4e5e9" />
          </linearGradient>
        </defs>
      </svg>
      {[...Array(totalStars)].map((star, index) => {
        const isHalf = rating > index && rating < index + 1;
        const isFull = rating > index;

        return (
          <div key={index} className="star-container">
            <label>

              <input
                type="radio"
                name="rating"
                value={rating}
                onClick={() => handleRating(index + 1,skill)}
                style={{ display: 'none' }} />
              {/* <svg
                className="star half-star"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill={rating <= index ? "#e4e5e9" : "#ffc107"}
              >
                <path d="M12 .587l3.668 7.571L24 9.753l-6 5.847 1.42 8.3L12
                18.897l-7.421 4.003L6 15.6l-6-5.847 8.332-1.595z" />
              </svg> */}
              <svg
                className="star"
                width="25"
                height="25"
                viewBox="0 0 24 24"
                fill={isFull ? "#ffc107" : "#e4e5e9"}
                style={{ position: "relative", display: "inline-block" }}
              >
                <path d="M12 .587l3.668 7.571L24 9.753l-6 5.847 1.42 8.3L12 18.897l-7.421
                 4.003L6 15.6l-6-5.847 8.332-1.595z" />
                {isHalf && (
                  <svg
                    className="half-star"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                    fill="url(#half-star)"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <path d="M12 .587l3.668 7.571L24 9.753l-6 5.847 1.42 8.3L12 18.897l-7.421
              4.003L6 15.6l-6-5.847 8.332-1.595z" />
                  </svg>
                )}
              </svg>

            </label>

          </div>
        );
      })}
    </div>
  );
};

StarRating.propTypes = {
  totalStars: PropTypes.number,
  handleRating: PropTypes.any,
  rating: PropTypes.any,
  skill: PropTypes.any,
};

export default StarRating;
