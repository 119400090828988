import React, { useEffect } from 'react';
import 'styles/App.css';
import { MainRoutes } from './routes';
import "language/i18n";

function App() {

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== 'http://localhost:3001') {
        localStorage.setItem("userLoginToken", JSON.stringify(event.data.userLoginToken))
        localStorage.setItem("tokenExpiryTime", (event.data.tokenExpiryTime))
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return <MainRoutes/>;
}

export default App;
