/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoSearch } from "react-icons/go";
import { GrFormClose } from "react-icons/gr";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchUserForChat } from "redux/appThunk/Employee/chat";

import Employeebrief from "component/Employee/Employeebrief";
import { assignResourceToAUser } from "reduxToolkit/JobSlice";

const AddResource = ({ id, handleClose, setAddResource, setAgainData }) => {
  const [serachedData, setSearchedData] = useState([]);
  const { searchResult } = useSelector((state) => state.jobs);
  const alreadyExistResource = [];
  const [resourses, setResourses] = useState([]);
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const handleEmployeedata2 = (item) => {
    setShow(false);
    setResourses([...resourses, item]);
    setSearch("");
  };

  const getColorFromEmail = (email) => {
    const hashCode = email
      ?.split("")
      ?.reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    const color = `#${(hashCode & 0x00ffffff)
      .toString(16)
      .toUpperCase()
      .padStart(6, "0")}`;

    return color;
  };

  const handleSubmit = () => {
    const ids = resourses?.map((obj) => obj.id);
    dispatch(
      assignResourceToAUser({ userId: id }, ids, setAddResource, setAgainData)
    );
    handleClose();
  };

  useEffect(() => {
    if (search !== "") {
      setShow(true);
      dispatch(handleSearchUserForChat(search));
    } else {
      setShow(false);
    }
  }, [search, dispatch]);

  useEffect(() => {
    if (searchResult) {
      const filteredData = searchResult?.filter(
        (obj) => !alreadyExistResource?.includes(obj?.id)
      );
      setSearchedData(filteredData);
    }
  }, [searchResult, dispatch]);

  useEffect(() => {
    if (search === "") {
      document.querySelector(".focus")?.focus();
    }
  }, [search]);

  const handleRemove = (id) => {
    setResourses((prevState) => prevState.filter((obj) => obj.id !== id));
  };

  return (
    <div className="w-full h-full flex items-center justify-center fixed top-0 left-0 bg-[rgba(3,27,89,.2)] z-30">
      <div
        className="min-w-[37.5rem] w-fit z-30 h-fit flex flex-col
        items-center gap-[1.5rem] bg-white rounded-[18px] shadow-lg"
      >
        {/* commented code for api integration */}
        <form className="w-full flex flex-col h-full" onSubmit={handleSubmit}>
          <div className="w-full px-7 py-5 flex justify-between items-center text-[#031B59] font-extrabold text-xl">
            <p>{t("assignResource")}</p>
            <button className="w-fit h-fit" onClick={handleClose}>
              <GrFormClose className="flex w-[2rem] h-[2rem]" />
            </button>
          </div>
          <div className="w-full flex flex-col">
            <hr />
            <div className="m-[1rem] p-[0.25rem] border rounded-[1rem] flex items-center justify-center">
              <div
                className="w-full max-w-[37.5rem] h-fit min-h-[45px]
                flex flex-wrap items-center justify-start gap-2 p-[0.5rem]"
              >
                {resourses?.map((obj, index) => (
                  <span
                    key={index}
                    className="p-2 flex flex-row items-center justify-normal gap-2
                  rounded-full bg-[#F2F6FF]"
                  >
                    {obj.name}
                    <GrFormClose
                      className="fill-[#A1A1A1] w-5 h-5 p-1 box-content cursor-pointer"
                      onClick={() => {
                        handleRemove(obj.id);
                      }}
                    />
                  </span>
                ))}
                <input
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  name="clientName"
                  className="focus w-[25rem] outline-none"
                />
              </div>
              <GoSearch className="w-[1.375rem] h-[1.375rem]" />
            </div>
            <div className="relative z-10 h-0 px-2">
              {show && (
                <div
                  className="flex justify-start flex-col w-full max-w-[36rem] bg-white group custom_scroll
                  shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-3 rounded-2xl z-10 overflow-y-auto max-h-60"
                >
                  {serachedData?.length > 0 ? (
                    serachedData.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full px-2 flex items-center space-x-2
                           cursor-pointer hover:bg-[#031B59] group rounded"
                          onClick={() => handleEmployeedata2(item)}
                        >
                          <div className="w-full flex flex-col">
                            <p className="text-[#313131] hover:text-white capitalize p-1">
                              <div className="flex items-center">
                                <div
                                  className="w-9 h-8 rounded-full flex items-center
                                  justify-center text-white bg-[#031B59] mr-2"
                                  style={{
                                    backgroundColor: getColorFromEmail(
                                      item.email
                                    ),
                                  }}
                                >
                                  {item.email?.[0]?.toUpperCase()}
                                </div>
                                <Employeebrief
                                  email={item.email}
                                  names={item.name}
                                />
                              </div>
                            </p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-[#031B59]">{t("noUserFound")}</p>
                  )}
                </div>
              )}
            </div>
            <hr />
          </div>
          <div className="w-full py-5 flex justify-between items-center">
            <div className="w-full flex justify-end items-center gap-8 px-7">
              <button
                onClick={handleClose}
                className="flex w-[7.5rem] h-[3rem] p-[1rem] justify-center
                items-center gap-[0.5rem] rounded-[0.5rem] text-[#686868]"
              >
                {t("cancel")}
              </button>
              <button
                className={`flex h-[3rem] p-[1rem_1.875rem] min-w-[6rem] justify-center items-center gap-[0.5rem] 
                    rounded-[2.5rem] font-inter text-[1rem] font-bold leading-normal text-white bg-[#031B59]`}
                type="button"
                disabled={resourses.length <= 0}
                onClick={handleSubmit}
              >
                {t("add")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

AddResource.propTypes = {
  id: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

export default AddResource;
