import ProfileAvtar from 'component/common/ProfileAvtar';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { MdNotificationsNone } from 'react-icons/md';

const DashboardHeader = () => {
  const userData = JSON.parse(localStorage?.getItem("userLoginToken")) || {};
  const { t } = useTranslation();

  return (
    <div className='w-auto p-[1.78rem] flex justify-between items-center gap-[1rem]'>
    <div className='w-auto'>
      <div className=' sm:text-[1.25rem] lg:text-[1.5rem] flex font-extrabold gap-1'>
        <h1 className='text-[#031b59]'>
          {t('welcome_back')}
        </h1>
        <p className='text-[#ed6e0f] capitalize'>
          {userData?.full_name}
        </p>
      </div>
    </div>
    <div className='flex sm:gap-[0.5rem] lg:gap-[1.8rem] items-center'>
      <div className='flex items-center gap-2'>
        <ProfileAvtar
          height="3rem"
          width="3rem"
          name={"naresh sharma"}
        />
        <div className='w-auto'>
          <h1 className='text-[0.75rem] font-medium capitalize text-[#686868]'>{t('repotManag')}</h1>
          <p className='text-[1rem] font-bold capitalize text-[#031b59]'>{"naresh sharma"}</p>
        </div>
      </div>
      <div className='text-[1.7rem] text-[#686868]'>
        <MdNotificationsNone />
      </div>
    </div>
  </div>
  )
}

export default DashboardHeader