import React, { useState } from "react";
import ScheduleEventPopUp from "./ScheduleEventPopUp";
import ClientInterviewPopUp from "./ClientInterviewPopUp";
import CandidateInterviewPopUp from "./CandidateInterviewPopUp";
import { useTranslation } from "react-i18next";

const EventTypeDropdown = ({ toggleAddEventModal }) => {
  const [selectedEvent, setSelectedEvent] = useState("schedule");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("schedule");
  const toggleDropdown = () => setIsOpen(!isOpen);
  const { t } = useTranslation();

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="w-full max-w-[35rem] pl-4 mt-10 overflow-y-scroll">
      <label
        htmlFor="eventType"
        className="block text-sm font-medium text-gray-700 pl-4"
      >
        {t("eventType")}
      </label>
      <div className="relative inline-block w-[31.8rem] ml-4">
        <div
          className="bg-white border border-gray-300 rounded-md px-4 py-2 cursor-pointer"
          onClick={toggleDropdown}
        >
          <span>{selectedOption}</span>
          <svg
            className={`w-5 h-5 inline-block float-right transition-transform ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
        {isOpen && (
          <ul className="absolute w-full bg-white border border-gray-300 rounded-md mt-1 shadow-lg z-10">
            {["schedule", "client_interview", "candidate_interview"].map(
              (option) => (
                <li
                  key={option}
                  className={`px-4 py-2 cursor-pointer hover:bg-[#F2F6FF] ${
                    selectedOption === option ? "bg-[#F2F6FF]" : ""
                  }`}
                  onClick={() => {
                    selectOption(option);
                    setSelectedEvent(option);
                  }}
                >
                  {option}
                </li>
              )
            )}
          </ul>
        )}
      </div>
      {/* Conditional rendering based on selected event */}
      <div className="mt-4">
        {/* Schedule event */}
        {selectedEvent === "schedule" && (
          <ScheduleEventPopUp
            selectedOption={selectedOption}
            toggleAddEventModal={toggleAddEventModal}
          />
        )}
        {/* Client Interview */}
        {selectedEvent === "client_interview" && (
          <ClientInterviewPopUp
            selectedOption={selectedOption}
            toggleAddEventModal={toggleAddEventModal}
          />
        )}
        {/* Candidate Interview */}
        {selectedEvent === "candidate_interview" && (
          <CandidateInterviewPopUp
            selectedOption={selectedOption}
            toggleAddEventModal={toggleAddEventModal}
          />
        )}
      </div>
    </div>
  );
};

export default EventTypeDropdown;
