import React, { useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { IoChevronForwardSharp } from "react-icons/io5";
import FeedbackTab from "./FeedbackTab";
import DetailTab from "./DetailTab";
import { useTranslation } from "react-i18next";
const CandidateEventDetailPopUp = ({ handleClose }) => {
  const [activeTab, setActiveTab] = useState("details");
  const { t } = useTranslation();

  const documents = [
    {
      name: "Tanushree Resume.pdf",
      link: "/path/to/document.pdf",
      imgSrc: "https://via.placeholder.com/40",
    },
  ];

  return (
    <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)]">
      <div
        className=" w-full max-w-xl h-full bg-white flex flex-col
   space-y-4 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transition-all pr-8  overflow-y-scroll"
      >
        <div className="w-full  items-center bg-white flex justify-between py-4 px-4">
          <div className="flex w-full items-center">
            <div>
              <button
                className={`px-4 py-2 ${
                  activeTab === "details"
                    ? " font-bold text-[#031B59]"
                    : "font-bold text-gray-500"
                }`}
                onClick={() => setActiveTab("details")}
              >
                {t("details")}
              </button>
              <button
                className={`px-4 py-2 ${
                  activeTab === "feedback"
                    ? "font-bold text-[#031B59]"
                    : "font-bold text-gray-500"
                }`}
                onClick={() => setActiveTab("feedback")}
              >
                {t("feedBack")}
              </button>
            </div>
          </div>
          <button onClick={() => handleClose(false)}>
            <RxCross2 />
          </button>
        </div>

        <div className=" overflow-y-scroll m-0 ">
          {/* {activeTab === "feedback" && <CandidateFeedbackEventDetailPopUp />} */}
        </div>

        {activeTab === "details" && (
          <>
            {" "}
            <div className="px-4  w-[34rem] ml-4">
              <label className="block text-sm font-normal text-[#686868]">
                {t("candidateName")}
              </label>
              <input
                type="text"
                value=""
                placeholder={t("candidateName")}
                className="mt-1 block w-[31rem]"
              />
            </div>
            <div className="px-4  w-[34rem] ml-4">
              <label className="block text-sm font-normal text-[#686868]">
                {t("title")}
              </label>
              <input
                type="text"
                value="Writing a title here"
                placeholder="Writing a title here"
                className="mt-1 block w-[31rem]"
              />
            </div>
            <div className="px-4  w-[34rem] ml-4">
              <label className="block text-sm font-normal text-[#686868]">
                {t("desc")}
              </label>
              <textarea
                className="w-[95%] h-[9.5rem]"
                name=""
                id=""
                cols="30"
                rows="10"
              >
              </textarea>
            </div>
            <div className="px-4  w-[34rem] ml-4">
              <label className="block text-sm font-normal text-[#686868]">
                {t("assignee")}
              </label>
              <input
                type="text"
                value=""
                placeholder={t("assignee")}
                className="mt-1 block w-[31rem]"
              />
            </div>
            <div className="px-4  w-[34rem] ml-4">
              <label className="block text-sm font-normal text-[#686868]">
                {t("interview_details")}
              </label>
              <input
                type="text"
                value=""
                placeholder={t("interview_details")}
                className="mt-1 block w-[31rem]"
              />
            </div>
            <div className="px-4  w-[34rem] ml-4">
              <p className="block text-sm font-normal text-[#686868]" />   
            </div>
            <div className="px-4  w-[34rem] ml-4">
              <a
                href="https://meet.google.com"
                className="text-[#012FF2] underline"
              >  
              </a>
            </div>
            <div className="px-4  w-[34rem] ml-4">
              <h2 className="text-gray-500 text-sm">{t("uploaded_documents")}</h2>
              {documents.map((doc, index) => (
                <div
                  className="flex gap-2 items-center border w-fit rounded-lg px-3 py-1 mt-2"
                  key={index}
                >
                  <img src={doc.imgSrc} alt="PDF" className="w-10 h-10" />
                  <a href={doc.link} download className="text-[#1B4FB6] ">
                    <span className="text-[#A1A1A1] ">{doc.name}</span>
                  </a>
                  <span className="text-[#A1A1A1] text-xl">
                    <AiOutlineDownload />
                  </span>
                </div>
              ))}
            </div>
            <div className="px-4  w-[34rem] ml-4">
              <label className="block text-sm font-normal text-[#686868]">
                {t("result")}
              </label>
              <input
                type="text"
                value=""
                placeholder={t("result")}
                className="mt-1 block w-[31rem]"
              />
            </div>
            <div className="px-4  w-[34rem] ml-4 flex justify-between">
              <button className="block text-sm font-normal text-[#031B59]">
                {t("feedback")}
              </button>
              <span>
                <IoChevronForwardSharp />
              </span>
            </div>
          </>
        )}
        {/* {activeTab === "writeFeedback" && <SkillEvaluationPopUp />} */}
      </div>
    </div>
  );
};

export default CandidateEventDetailPopUp;





