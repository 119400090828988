import DatePicker from "component/common/DatePicker";
import LoaderComp from "component/loader/LoaderComp";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoToggle } from "react-icons/io5";
import { SlCloudUpload } from "react-icons/sl";
import { useDispatch } from "react-redux";
import { postClientEvents } from "reduxToolkit/slices/calender";
import { formatTime } from "utils/date";
import * as Yup from "yup";

const ClientInterviewPopUp = ({
  jobTitle,
  job,
  filterEvent,
  setAgainData,
  setIsopen,
  toggleAddEventModal,
  selectedOption,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState([]);
  const [formDataId, setFormDataId] = useState([]);
  const [loder, setLoder] = useState(false);
  const dispatch = useDispatch();
  const formattedEndTime = formatTime(filterEvent?.endTime);
  const formattedStartTime = formatTime(filterEvent?.startTime);

  useEffect(() => {
    if (formData) {
      const formDataNew = formData?.map((item) => item.id);
      setFormDataId(formDataNew);
    }
  }, [formData]);

  const handleRemoveImage = () => {
    setFieldValue(`Upload_Image`, "");
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setFieldValue("Upload_Image", files[0]);
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Please add Title"),
    date: Yup.string().required("Invalid date format"),
    startTime: Yup.string().required("Please add start time"),
    endTime: Yup.string()
      .required("Please add end time")
      .test(
        "is-greater",
        "End time should be greater than start time",
        function (value) {
          const { startTime } = this.parent;
          return startTime && value > startTime;
        }
      ),
    round: Yup.string().required("Please add rounds"),
  });

  const handleClose = () => {
    toggleAddEventModal();
  };

  const Formik = useFormik({
    initialValues: {
      client_name: "",
      title: jobTitle || "",
      date: filterEvent
        ? new Date(filterEvent.date).toISOString().split("T")[0]
        : "",
      startTime: formattedStartTime || "",
      endTime: formattedEndTime || "",
      round: filterEvent?.round || "",
      description: filterEvent?.desc || "",
      budget: false, 
      Upload_Image: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const newFile = new File(["foo"], job?.resumeUrl?.name, {
        type: job?.resumeUrl?.type,
      });
      const newForm = new FormData();
      if (filterEvent) {
        const json = {
          event: {
            event_type: "",
            title: values.title,
            date: values.date,
            start_time: values.startTime,
            end_time: values.endTime,
            description: values.description,
            budget: values.budget,
            event_documents_urls: newFile,
          },
        };
        // needed when update
        // dispatch(updateEvent(filterEvent?.id, json, setLoder, setIsopen, setAgainData));
      } else {
        newForm.append("event[event_type]", selectedOption);
        newForm.append("event[client_name]", values.client_name);
        newForm.append("event[title]", values.title);
        newForm.append("event[date]", values.date);
        newForm.append("event[start_time]", values.startTime);
        newForm.append("event[end_time]", values.endTime);
        newForm.append("event[description]", values.description);
        newForm.append("event[budget]", values.budget);
        newForm.append("event[event_documents]", values.Upload_Image);
        try {
          await dispatch(
            postClientEvents(
              newForm,
              handleClose,
              resetForm,
              setIsopen,
              setLoder,
              setAgainData
            )
          );
        } catch (error) {
          console.error("API Error:", error);
        }
      }
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
  } = Formik;

  return (
    <div className="p-4 pt-0 rounded-md flex flex-col gap-5">
      <FormikProvider value={Formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className="space-y-4 overflow-y-auto custom_scroll"
        >
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("client_name")}
            </label>
            <input
              type="text"
              name="client_name"
              value={values.client_name}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("client_name")}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {errors.title && touched.title && (
              <p className="text-[red] w-80">{errors.client_name}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("resource_name")}
            </label>
            <input
              type="text"
              name="resource_name"
              value={values.resource_name}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("resource_name")}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {errors.title && touched.title && (
              <p className="text-[red] w-80">{errors.resource_name}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("title")}
            </label>
            <input
              type="text"
              name="title"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("title")}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {errors.title && touched.title && (
              <p className="text-[red] w-80">{errors.title}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("desc")}
            </label>
            <textarea
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={4}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {errors.description && touched.description && (
              <p className="text-[red] w-80">{errors.description}</p>
            )}
          </div>
          <div className="flex justify-between items-center">
            <p>{t("budget")}</p>
            <span className="text-xl">
              <IoToggle />
            </span>
          </div>
          <div className="flex justify-between items-center">
            <p>{t("interview_details")}</p>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("date")}
            </label>
            <DatePicker
              name="date"
              value={values.date}
              handleChange={handleChange}
              onBlur={handleBlur}
              min={new Date().toISOString().split("T")[0]}
              className="h-[2.625rem] w-full"
            />
            {errors.date && touched.date && (
              <p className="text-[red] w-80">{errors.date}</p>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("startTime")}
              </label>
              <input
                name="startTime"
                type="time"
                value={values.startTime}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
              {errors.startTime && touched.startTime && (
                <p className="text-[red] w-80">{errors.startTime}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("endTime")}
              </label>
              <input
                name="endTime"
                type="time"
                value={values.endTime}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
              {errors.endTime && touched.endTime && (
                <p className="text-[red] w-80">{errors.endTime}</p>
              )}
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("round")}
            </label>
            <input
              type="text"
              name="round"
              value={values.round}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("round")}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            {errors.round && touched.round && (
              <p className="text-[red] w-80">{errors.round}</p>
            )}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("document")}
            </label>
            {!values.Upload_Image ? (
              <div className="flex flex-col border border-gray-300 rounded-md p-4 items-center mt-1 cursor-pointer">
                <label
                  htmlFor="upload"
                  className="flex flex-col items-center cursor-pointer"
                >
                  <SlCloudUpload />
                  <p className="font-bold">{t("dragFile")}</p>
                </label>
                <input
                  id="upload"
                  type="file"
                  name="Upload_Image"
                  onChange={handleImageChange}
                  onBlur={handleBlur}
                  className="hidden"
                />
              </div>
            ) : (
              <div className="flex justify-between items-center mt-1 border border-gray-300 rounded-md p-4">
                <p className="text-sm">{values.Upload_Image.name}</p>
                <button
                  type="button"
                  onClick={handleRemoveImage}
                  className="text-red-500"
                >
                  {t("remove")}
                </button>
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="mt-4 px-9 py-3 rounded-[2.5rem] bg-[#031B59] text-white  w-fit"
            >
              {loder ? <LoaderComp /> : t("schedule")}
            </button>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default ClientInterviewPopUp;
