import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { GrFormClose } from "react-icons/gr";
import LoadSpinner from "../loadingSpinner";

const ConfirmationModal = ({
  isOpen,
  isPending,
  title,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isOpen && (
        <div
          className="w-full h-full flex items-center
          justify-center fixed top-0 left-0 z-50  bg-[rgba(3,27,89,.2)] op"
        >
          <div
            className="w-[37.5rem] h-[15.75rem] z-60 flex flex-col rounded-br-[2rem]
            items-center gap-[1.5rem] justify-center bg-white drop-shadow-lg"
          >
            <button onClick={onClose}>
              <GrFormClose className="flex absolute gap-[0.625rem] w-[2rem] h-[2rem] top-[1rem] right-[1rem]" />
            </button>
            <hr />
            <div
              className="w-[32.5rem] h-[4rem] color-[#191919] font-inter font-bold
              text-2xl leading-8 tracking-[0.0075rem]"
            >
              {title}
            </div>
            <div
              className="flex w-[37.5rem] p-[1.5rem_2.5rem] justify-end items-center gap-[1rem] rounded-br-[2rem]
              border-t-[1px] border-[#E2E8F0] bg-white"
            >
              <button>
                <div
                  onClick={() => onClose()}
                  className="flex w-[7.5rem] h-[3.5rem] p-[1rem] justify-center items-center
                    gap-[0.5rem] rounded-[0.5rem]"
                >
                  {t("cancel")}
                </div>
              </button>
              {isPending && <div className="w-fit"> <LoadSpinner /> </div>}
              {!isPending && (
                <button onClick={() => onConfirm()}>
                  <div
                    className="flex h-[3.5rem] p-[1rem_1.875rem] justify-center items-center gap-[0.5rem]
                  rounded-[2.5rem] bg-[#031B59] font-inter text-[1rem] font-bold leading-normal text-white"
                  >
                    Yes
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmationModal.defaultProps = {
  isPending: false,
};

export default ConfirmationModal;
