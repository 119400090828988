/* eslint-disable max-len */
import React from "react";

const Svg19 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.683 11.4793L5.86496 5.06162C5.76449 5.01131 5.65165 4.99115 5.54001 5.00357C5.42837 5.01599 5.32269 5.06046 5.23571 5.13163C5.15264 5.20134 5.09063 5.29288 5.05667 5.39595C5.0227 5.49901 5.01812 5.60952 5.04344 5.71505L6.58743 11.4151H13.1771V12.582H6.58743L5.02013 18.2645C4.99638 18.3527 4.9936 18.4451 5.01204 18.5345C5.03047 18.6239 5.06959 18.7078 5.12626 18.7793C5.18294 18.8508 5.25557 18.908 5.33833 18.9462C5.42109 18.9845 5.51166 19.0028 5.60277 18.9997C5.69398 18.9991 5.78378 18.9771 5.86496 18.9355L18.683 12.5178C18.7785 12.4688 18.8586 12.3945 18.9145 12.3028C18.9704 12.2112 19 12.1059 19 11.9985C19 11.8912 18.9704 11.7859 18.9145 11.6943C18.8586 11.6026 18.7785 11.5283 18.683 11.4793Z"
        fill="#A1A1A1"
      />
    </svg>
  );
};

export default Svg19;
