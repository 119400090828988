import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import EventPopUp from "./EventPopUp";
import AddEventPopUp from "./AddEventPopUp";
import ScheduleEventDetailPopUp from "./ScheduleEventDetailPopUp";
import ScheduleEventPopUp from "./ScheduleEventPopUp";
import { RxCross2 } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import ClientInterviewPopUp from "./ClientInterviewPopUp";
import CandidateEventDetailPopUp from "./CandidateEventDetailPopUp";
import EventDetails from "./EventDetails";
import { IoClose } from "react-icons/io5";
import SearchCalender from "./SearchCalender";
const CalendarHeader = ({ onChangeView, previous, next, view }) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isEventOpen, setIsEventOpen] = useState(false);
  const [IsAddEventOpen, setIsAddEventOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isScedulOpen, setIsScedulOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isCandidateOpen, setIsCandidateOpen] = useState(false);
  const [isClientOpen, setIsClientOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const searchRef = useRef();

  const toggleAddEventModal = () => {
    setIsEditOpen(false);
    setIsAddEventOpen(!IsAddEventOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleEventModal = () => {
    setIsEventOpen(!isEventOpen);
  };

  const [events, setEvents] = useState([
    {
      id: 0,
      title: "Meeting",
      start: new Date(2024, 8, 29, 10, 0),
      end: new Date(2024, 8, 29, 12, 0),
      allDay: false,
    },
  ]);

  const handleSelectSlot = (slotInfo) => {
    const title = true;
    setIsAddEventOpen(true);
    if (title) {
      setEvents([
        ...events,
        {
          id: events.length,
          title,
          start: slotInfo.start,
          end: slotInfo.end,
          allDay: false,
        },
      ]);
    }
  };

  const handleInnerMouseClick = () => {};

  const handleViewChange = (view) => {
    setIsDropdownOpen(false);
    if (onChangeView) {
      onChangeView(view);
    }
  };

  // month
  const formatMonthYear = (date) => {
    const options = {
      month: "long",
    };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };
  // date
  const formatDate = (date) => {
    const options = {
      weekday: "long",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };
  const goToPreviousMonth = () => {
    if (view === "month") {
      const newDate = new Date(currentDate);
      newDate.setMonth(newDate.getMonth() - 1);
      setCurrentDate(newDate);
      previous();
    } else if (view === "day") {
      const newDate = new Date(currentDate);
      newDate.setDate(newDate.getDate() - 1);
      setCurrentDate(newDate);
      previous();
    } else if (view === "week") {
      const newDate = new Date(currentDate);
      newDate.setDate(newDate.getDate() - 7);
      setCurrentDate(newDate);
      previous();
    }
  };
  const goToNextMonth = () => {
    if (view === "month") {
      const newDate = new Date(currentDate);
      newDate.setMonth(newDate.getMonth() + 1);
      setCurrentDate(newDate);
      next();
    } else if (view === "day") {
      const newDate = new Date(currentDate);
      newDate.setDate(newDate.getDate() + 1);
      setCurrentDate(newDate);
      next();
    } else if (view === "week") {
      const newDate = new Date(currentDate);
      newDate.setDate(newDate.getDate() + 7);
      setCurrentDate(newDate);
      next();
    }
  };

  const handleViewEvent = (value) => {
    switch (value) {
      case 0:
        setIsScedulOpen(false);
        break;
      case 1:
        // needed
        // setIsCandidateOpen(true);
        break;
      case 2:
        setIsClientOpen(true);
        break;
      default:
        return null;
    }
  };

  return (
    <div className="flex justify-between items-center p-4 w-full">
      {/* Left section with date and arrows */}
      <div className="flex flex-col pl-2">
        <div className="text-[#031B59] text-xl font-semibold flex justify-between w-40">
          <h5>{formatMonthYear(currentDate)}</h5>
          <div className="flex ml-2 space-x-2">
            <button className="text-[#031B59]" onClick={goToPreviousMonth}>
              <IoIosArrowBack />
            </button>
            <button className="text-[#031B59]" onClick={goToNextMonth}>
              <IoIosArrowForward />
            </button>
          </div>
        </div>
        <div className=" text-gray-500 text-sm">{formatDate(currentDate)}</div>
      </div>

      {/* Right section with dropdown and buttons */}
      <div className="flex items-center space-x-4">
        <div>
          <SearchCalender
            setSearchOpen={setSearchOpen}
            searchOpen={searchOpen}
            setSearchItem={setSearchItem}
            searchItem={searchItem}
            searchRef={searchRef}
            handleInnerMouseClick={handleInnerMouseClick}
          />
        </div>
        {!searchOpen && (
          <>
            {/* Dropdown Button */}
            <div className="relative">
              <button
                className="bg-[#031B59] text-white px-4 py-2 rounded-full flex items-center"
                onClick={toggleDropdown}
              >
                {view.charAt(0).toUpperCase() + view.slice(1)}{" "}
                <span className="ml-2">
                  <IoIosArrowDown />
                </span>
              </button>
              {isDropdownOpen && (
                <div className="absolute pt-[0.6rem] bg-white rounded-lg shadow-lg z-10">
                  <ul className="py-1 px-2 border rounded-lg">
                    <li
                      className="px-4 py-2 hover:bg-[#F2F6FF] cursor-pointer"
                      onClick={() => handleViewChange("month")}
                    >
                      {t("monthly")}
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-[#F2F6FF] cursor-pointer"
                      onClick={() => handleViewChange("week")}
                    >
                      {t("weekly")}
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-[#F2F6FF] cursor-pointer"
                      onClick={() => handleViewChange("day")}
                    >
                      {t("days")}
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Add Event Button */}
            <button
              className="border border-gray-300 text-[#031B59] px-4 py-2 rounded-full flex items-center"
              onClick={handleSelectSlot}
            >
              <span className="mr-2">+</span> {t("addEvent")}
            </button>

            {/* Go to Events Button */}
            <button
              className="border border-gray-300 text-[#031B59] px-4 py-2 rounded-full"
              onClick={handleEventModal}
            >
              {t("goToEvents")}
            </button>
            {isEventOpen && (
              <EventPopUp
                handleEventModal={handleEventModal}
                setIsEventOpen={setIsEventOpen}
                setIsScedulOpen={setIsScedulOpen}
                handleViewEvent={handleViewEvent}
              />
            )}
            {isScedulOpen && (
              <EventDetails
                setPopup={setIsEventOpen}
                setIsEditOpen={setIsEditOpen}
                isScedulOpen={setIsScedulOpen}
                setIsAddEventOpen={setIsAddEventOpen}
              />
            )}
            {isCandidateOpen && (
              <CandidateEventDetailPopUp handleClose={setIsCandidateOpen} />
            )}
            {isClientOpen && (
              <ClientInterviewPopUp handleClose={setIsClientOpen} />
            )}
            {IsAddEventOpen && (
              <AddEventPopUp toggleAddEventModal={toggleAddEventModal} />
            )}
            {isEditOpen && (
              <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)]">
                <div
                  className=" w-full max-w-[37rem] h-full bg-white flex flex-col
        space-y-4 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transition-all overflow-y-scroll"
                >
                  <div className="w-full h-[42px] items-center bg-white flex justify-between p-7">
                    <div className="flex justify-center items-center pl-4">
                      <h2 className=" text-[1.5rem] font-bold text-[#031B59]">
                        {t("addEvent")}
                      </h2>
                    </div>
                    <div
                      className="text-[1.5rem] cursor-pointer mr-[-.64rem]"
                      onClick={handleEventModal}
                    >
                      <span
                        className="pr-4 flex"
                        onClick={() => setIsEditOpen(false)}
                      >
                        <RxCross2 />
                      </span>
                    </div>
                  </div>
                  <div className="px-8  w-[34rem] ml-4">
                    <label className="block text-sm font-medium text-gray-700">
                      {t("eventType")}
                    </label>
                    <input
                      type="text"
                      placeholder="Schedule"
                      className="mt-1 block  p-2 border border-gray-300 rounded-md w-[31rem]"
                    />
                  </div>
                  <div className="px-8">
                    <ScheduleEventPopUp handleClose={setIsEditOpen} />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CalendarHeader;
