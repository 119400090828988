import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CalendarHeader from "component/calender/CalenderHeader";

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const [view, setView] = useState("month");
  const [events, setEvents] = useState([
    {
      id: 0,
      title: "hello 1",
      start: new Date(new Date().setHours(14, 0, 0)),
      end: new Date(new Date().setHours(15, 0, 0)),
      allDay: false,
    },
    {
      id: 1,
      title: "hello 2",
      start: new Date(new Date().setHours(8, 0, 0)),
      end: new Date(new Date().setHours(9, 30, 0)),
      allDay: false,
    },
    {
      id: 2,
      title: "hello hai",
      start: new Date(2024, 9, 8, 10, 0), 
      end: new Date(2024, 9, 8, 11, 0), 
      allDay: false,
    },
  ]);

  const handleSelectSlot = (slotInfo) => {
    const title = window.prompt("New Event Name");
    if (title) {
      setEvents([
        ...events,
        {
          id: events.length,
          title,
          start: slotInfo.start,
          end: slotInfo.end,
          allDay: false,
        },
      ]);
    }
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleEventClick = (event) => {
    if (window.confirm(`Do you want to remove the event: ${event.title}?`)) {
      setEvents(events.filter((e) => e.id !== event.id));
    }
  };

  return (
    <>
    <div className="h-screen w-full p-4 bg-gray-100">
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        selectable
        onSelectSlot={handleSelectSlot}
        onSelectEvent={handleEventClick}
        view={view}
        onView={handleViewChange}
        components={{
          toolbar: (props) => (
            <div className="pb-2">
              {
                <CalendarHeader
                  previous={() => props.onNavigate("PREV")}
                  next={() => props.onNavigate("NEXT")}
                  onChangeView={handleViewChange}
                  view={view}
                />
              }
            </div>
          ),
          event: ({ event }) => (
            <div className="px-2 py-1 rounded-md  rbc-event-text-green">
              {event.title}
            </div>
          ),
          month: {
            dateHeader: ({ label }) => (
              <div className="text-center font-semibold">{label}</div>
            ),
          },
        }}
        className="bg-white rounded-lg shadow-lg border"
      />
    </div>
    </>
  );
};

export default MyCalendar;
