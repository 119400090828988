import { useEffect, useState } from "react";
import { useOnClickOutside } from "./useOneClickOutsid";

export const ClickOutsideClose = (openFilter,setIsClicked,ref, handler) => {

  
    useEffect(() => {
      if (!openFilter) {
        setTimeout(() => {
          setIsClicked(true);
        }, [500])
  
      }
    }, [openFilter])

    useOnClickOutside(ref, () => handler());
 
};
