import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RxCross1 } from "react-icons/rx";
import FeedbackEventDetails from "./FeedbackEventDetails";
import Tab from "component/common/Tabs";
import ScheduleEventDetailPopUp from "./ScheduleEventDetailPopUp";

const TAB_DETAILS = 1;
const TAB_FEEDBACK = 2;

const EventDetails = ({
  setPopup,
  setIsEditOpen,
  isScedulOpen,
  setIsAddEventOpen,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(TAB_DETAILS);

  const handleCloseDetails = () => {
    isScedulOpen(false);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-end z-30 bg-[rgba(3,27,89,.2)]">
      <div className="flex flex-col w-full max-w-[42rem] h-full bg-white space-y-4 z-40 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transition-all">
        <div>
          <div className="flex justify-between px-6 py-7 items-center">
            <div className="flex gap-10 text-[1.3rem] font-semibold">
              <Tab
                title={t("details")}
                isActive={activeTab === TAB_DETAILS}
                onClick={() => setActiveTab(TAB_DETAILS)}
              />
              <Tab
                title={t("feedBack")}
                isActive={activeTab === TAB_FEEDBACK}
                onClick={() => setActiveTab(TAB_FEEDBACK)}
              />
            </div>
            <button
              className="p-1 text-[1.2rem]"
              onClick={handleCloseDetails}
              aria-label="Close"
            >
              <RxCross1 />
            </button>
          </div>
          <hr />
          <div>
            {activeTab === TAB_DETAILS && (
              <ScheduleEventDetailPopUp
                setPopup={setPopup}
                setIsEditOpen={setIsEditOpen}
                isScedulOpen={isScedulOpen}
                setIsAddEventOpen={setIsAddEventOpen}
              />
            )}
            {activeTab === TAB_FEEDBACK && <FeedbackEventDetails />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
