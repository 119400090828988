import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa";

const PdfPreview = ({
  setPreview,
  fileData,
  values,
  jobDetails,
  formData,
  inputText,
}) => {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  const [imageUrl, setImageUrl] = useState(null);
  const { t } = useTranslation();
  let currentDate = `${day}/${month}/${year}`;

  useEffect(() => {
    if (fileData) {
      const objectUrl = URL.createObjectURL(fileData);
      setImageUrl(objectUrl);
      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [fileData]);
  return (
    <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)]">
      <div className="min-w-[60rem] w-full h-full bg-white flex flex-col shadow-lg max-w-[65rem] transition-all">
        <div className=" h-full">
          {/* Header */}
          <div className="w-full p-5 bg-white flex justify-between border-b">
            <div className="flex items-center font-extrabold gap-5">
              <div className="cursor-pointer" onClick={() => setPreview(false)}>
                <FaChevronLeft />
              </div>
              <h2 className="font-extrabold text-xl text-[#031B59]">Preview</h2>
            </div>
          </div>

          {/* Offer Letter Content with Background Image */}
          <div className="h-[91vh] overflow-y-auto px-8 py-4 italic">
            <div className="border border-gray-300 p-[10px_20px_30px_40px] bg-opacity-90 rounded-lg shadow-md bgPreviewImg flex flex-col justify-between">
              <div>
                <div className="flex justify-end  mr-[4.3rem] mb-2 mt-[9rem]">
                  <p className="text-right font-semibold">
                    Date: {currentDate}
                  </p>
                </div>

                <p>
                  To,
                  <br />
                  <h1 className="font-semibold capitalize">
                    {jobDetails?.jobFullName}
                  </h1>
                </p>
                <p className="mt-4 font-semibold capitalize">
                  {t("subject")}{" "}
                  {values?.letterType === "Other"
                    ? inputText
                    : values?.letterType}{" "}
                  {t("forThePost")} {jobDetails?.appliedFor}
                </p>

                <p className="mt-4 capitalize">
                  Dear {jobDetails?.jobFullName},
                </p>
                <div
                  className="mt-4 break-all"
                  dangerouslySetInnerHTML={{ __html: values?.description }}
                />
              </div>
              <div>
                <p className="mt-8">Yours Sincerely,</p>
                <div className=" text-left object-fill">
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="Signature"
                      className="h-16 w-30 "
                    />
                  ) : (
                    <p className="text-[red] font-light">
                      {"No signature uploaded"}
                    </p>
                  )}
                </div>
                <p className="mt-1 font-semibold capitalize">
                  {formData?.name || (
                    <span className="text-[red] font-light">
                      {"No name of assigned"}
                    </span>
                  )}
                  <br />
                  {formData?.designation}
                </p>
              </div>
            </div>

            <div className="border border-gray-300 p-[10px_20px_30px_40px] bg-opacity-90 rounded-lg shadow-md bgPreviewImg flex flex-col justify-between mt-8">
              <div>
                <h1>{t("timeLineHead")}</h1>
                <p>
                  <br />
                  <h1 className="font-semibold capitalize">
                    {jobDetails?.jobFullName}
                  </h1>
                </p>
                <p className="mt-4 font-semibold capitalize">
                  {t("subject")}{" "}
                  {values?.letterType === "Other"
                    ? inputText
                    : values?.letterType}{" "}
                  {t("forThePost")} {jobDetails?.appliedFor}
                </p>

                <p className="mt-4 capitalize">
                  Dear {jobDetails?.jobFullName},
                </p>
                <div
                  className="mt-4 break-all"
                  dangerouslySetInnerHTML={{ __html: values?.description }}
                />
              </div>
              <div>
                <p className="mt-8">Yours Sincerely,</p>
                <div className=" text-left object-fill">
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="Signature"
                      className="h-16 w-30 "
                    />
                  ) : (
                    <p className="text-[red] font-light">
                      {"No signature uploaded"}
                    </p>
                  )}
                </div>
                <p className="mt-1 font-semibold capitalize">
                  {formData?.name || (
                    <span className="text-[red] font-light">
                      {"No name of assigned"}
                    </span>
                  )}
                  <br />
                  {formData?.designation}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfPreview;
