/* eslint-disable max-len */
// eslint-disable-next-line max-len
import React from 'react';

const InprogressJob = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M21.9082 5.99439L12.0282 15.8744L8.5482 12.4044C8.36137 12.2171 8.10772 12.1119 7.8432 12.1119C7.57869 12.1119 7.32503 12.2171 7.1382 12.4044C6.7482 12.7944 6.7482 13.4244 7.1382 13.8144L11.3182 17.9944C11.7082 18.3844 12.3382 18.3844 12.7282 17.9944L23.3182 7.41439C23.7082 7.02439 23.7082 6.39439 23.3182 6.00439H23.3082C22.9282 5.60439 22.2982 5.60439 21.9082 5.99439ZM17.6682 6.0044C17.4813 5.81715 17.2277 5.71191 16.9632 5.71191C16.6986 5.71191 16.445 5.81715 16.2582 6.0044L10.6182 11.6444L12.0282 13.0544L17.6682 7.4044C18.0482 7.0244 18.0482 6.3844 17.6682 6.0044ZM5.66781 18.0044L1.48781 13.8244C1.09781 13.4344 1.09781 12.7944 1.48781 12.4044C1.67464 12.2171 1.92829 12.1119 2.19281 12.1119C2.45733 12.1119 2.71098 12.2171 2.89781 12.4044L7.77781 17.3044L7.07781 18.0044C6.68781 18.3944 6.05781 18.3944 5.66781 18.0044Z" fill="#A1A1A1"/>
    </svg>
  );
};

export default InprogressJob;
