import React from "react";
import { MdChatBubble } from "react-icons/md";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { filterSearchUser } from "redux/selector/Employee/chats";
import { setUsername } from "redux/actions/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileAvtar from "component/common/ProfileAvtar";

export default function Employeebrief({
  names,
  email,
  userName,
  userEmail,
  data,
  id,
  empcode,
  imageUrl,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChat = (data) => {
    if (data) {
      const getfilterSearchUser = filterSearchUser([data]);
      dispatch(setUsername(...getfilterSearchUser));
      navigate("/chat");
    }
  };
  const data1 = window.location.pathname;
  const emailName = email?.split("@")[0];

  return (
    <div className="group relative w-full  flex justify-start  items-center space-x-[10px]">
      {userEmail && !names && (
        <div className="w-12 h-12">
          {imageUrl ? (
            <img
              className="w-full h-full object-cover flex items-center border-2 border-white rounded-full "
              src={imageUrl}
              alt="Profile Avatar"
            />
          ) : (
            <ProfileAvtar
              height="3rem"
              width="3rem"
              name={names ? names : emailName}
            />
          )}
        </div>
      )}

      {email && userEmail && names && (
        <div className="w-12 h-12 ">
          {imageUrl ? (
            <img
              className="w-full h-full object-cover flex items-center border-2 border-white rounded-full "
              src={imageUrl}
              alt="Profile Avatar"
            />
          ) : (
            <ProfileAvtar
              height="3rem"
              width="3rem"
              name={names ? names : emailName}
            />
          )}
        </div>
      )}
      <div
        className="flex flex-col items-start  space-y-[2px] cursor-pointer"
        onClick={() => {
          if (id && data1.includes("status")) {
            navigate(`/timeSheet/view/${id}`);
          }
        }}
      >
        <h6 className="font-semibold capitalize">{names || emailName}</h6>
        <p className="text-[#A1A1A1] text-sm">{empcode}</p>
        <p className="text-[#A1A1A1] text-sm">{email}</p>
      </div>
      {userEmail && userName && (
        <div
          className="absolute left-[10rem] bottom-[.1rem] p-4  hidden w-[20rem] z-20
          h-[8rem] group-hover:flex flex-col items-center
        justify-center bg-[white]  rounded-lg  shadow-[0px_0px_20px_0px_rgba(3,27,89,0.10)]"
        >
          <div className="w-full flex gap-2  p-[10px]">
            <div className="h-[54px] w-[54px]  object-cover">
              {imageUrl ? (
                <img
                  className="w-full h-full object-cover flex items-center border-2 border-white rounded-full "
                  src={imageUrl}
                  alt="Profile Avatar"
                />
              ) : (
                <ProfileAvtar
                  height="3rem"
                  width="3rem"
                  name={names ? names : emailName}
                />
              )}
            </div>
            <div className="flex flex-col  items-start space-y-[2px]">
              <h6 className="text-[#191919] font-semibold">{userName}</h6>
              <p className="text-[#A1A1A1] text-sm">{userEmail}</p>
            </div>
          </div>
          <div
            className={` w-full flex flex-col  justify-between items-center`}
          >
            <hr className="w-[85%]" />
            <div className="w-full h-11 flex  items-center justify-center">
              <button
                className="h-[26px] w-[71px] flex items-center justify-center border space-x-1
              border-[#DFE1E3] rounded"
                onClick={() => handleChat(data)}
                data-testid="chat-button"
              >
                <MdChatBubble className="h-4 w-4 mt-[1px] fill-[#DFE1E3]" />
                <span className="text-xs" onClick={() => navigate(`/chat`)}>
                  {t("chat")}
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
Employeebrief.propTypes = {
  names: PropTypes.string,
  userName: PropTypes.string,
  email: PropTypes.string,
  userEmail: PropTypes.string,
  designation: PropTypes.string,
  data: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  empcode: PropTypes.string,
  imageUrl: PropTypes.any,
};
