import { createSlice } from "@reduxjs/toolkit";
import ToastServices from "ToastServices";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import { DeleteSelectEmployee } from "./JobSlice";

export const applicantSlice = createSlice({
  name: "applicants",
  initialState: {
    status: "",
  },
  reducers: {
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
  },
});

export const { setStatus } = applicantSlice.actions;

export const jobAddEmployee =
  (employeesData, job,setSubmit,setOpenAddEmp) => async (dispatch) => {
    try {
      dispatch(setStatus("loading"));
      setSubmit(true);
      const path = apiUrl.sign;
      const { response, status } = await Helper.post(employeesData, path);
      if (status === 200 || status === 201) {
        setSubmit(false);
        setOpenAddEmp(false);
        dispatch(DeleteSelectEmployee(job?.id));
        ToastServices.showToast({
          message: "Added New Employee !",
          type: "success",
          autoClose: 3000,
        });
      }
    } catch (error) {
      dispatch(setStatus("failed"));
      ToastServices.showToast({
        message: error?.message
          ? error?.message
          : "Failed to Add New Employee !",
        type: "error",
        autoClose: 3000,
      });
    } finally{
      setSubmit(false);
    }
  };

export default applicantSlice.reducer;
