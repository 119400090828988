import ProfileAvtar from "component/common/ProfileAvtar";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Svg19 } from "svgComponents/Svg";

const FeedbackEventDetails = () => {
  const [allTab, setAllTab] = useState(1);
  const { t } = useTranslation();

  const data = [
    { name: "full_name", label: "data" },
    { name: "comment", label: "guest" },
  ];

  const feedbackData = [
    {
      full_name: "Tanushree Khandelwal",
      comment:
        "s simply dummy text of the printing and typesetting industry. Lorem Ipsum hasbeen the industry standard dummy text ever since the 1500s, when an unknownprinter took a galley of type and scrambled it to make a type specimen book. It has",
      created_at: "4:29PM",
    },
    {
      full_name: "Tanushree Khandelwal",
      comment:
        "s simply dummy text of the printing and typesetting industry. Lorem Ipsum hasbeen the industry standard dummy text ever since the 1500s, when an unknownprinter took a galley of type and scrambled it to make a type specimen book. It has",
      created_at: "4:29PM",
    },
    {
      full_name: "Tanushree Khandelwal",
      comment:
        "s simply dummy text of the printing and typesetting industry. Lorem Ipsum hasbeen the industry standard dummy text ever since the 1500s, when an unknownprinter took a galley of type and scrambled it to make a type specimen book. It has",
      created_at: "4:29PM",
    },
    {
      full_name: "Tanushree Khandelwal",
      comment:
        "s simply dummy text of the printing and typesetting industry. Lorem Ipsum hasbeen the industry standard dummy text ever since the 1500s, when an unknownprinter took a galley of type and scrambled it to make a type specimen book. It has",
      created_at: "4:29PM",
    },
  ];

  const keysToMatch = data.map((item) => item.name);

  const filteredFeedback = feedbackData.map((feedback) => {
    const filteredEntry = {};
    keysToMatch.forEach((key) => {
      if (feedback[key]) {
        filteredEntry[key] = feedback[key];
      }
    });
    return filteredEntry;
  });

  return (
    <>
      <div className="flex flex-col space-y-3 pl-4 pr-4 items-center">
        <div className="w-full flex flex-col space-y-2">
          <label
            htmlFor="comment"
            className="text-[14px] font-medium text-[#686868]"
          >
            {t("writeFeedback")}
          </label>
          <textarea
            type="text"
            name="comment"
            id="comment"
            className="h-[6rem] border border-[#E2E8F0] bg-[#F2F6FF] rounded-[5px] p-3"
          />
          {/* {formik.touched.comment && formik.errors.comment && (
              <p className="text-red-500">{formik.errors.comment}</p>
            )} */}
          <div className="flex justify-end space-x-4 cursor-pointer">
            <button
              type="submit"
              // onClick={formik.handleSubmit}
              style={{ border: "1px solid black" }}
              // disabled={submitting}
            >
              <Svg19 className="h-[24px] w-[24px]" />
            </button>
          </div>
        </div>
        <div className="w-full h-16 bg-white flex justify-between items-center">
          <div className="h-12 bg-white flex justify-between items-center">
            <div
              className={`font-bold text-[16px] mr-[3rem] cursor-pointer ${
                allTab === 1 ? "text-[#031B59]" : "text-[#A1A1A1]"
              }`}
              onClick={() => setAllTab(1)}
            >
              {t("all")}
            </div>
            <div
              className={`font-bold text-[16px] cursor-pointer ${
                allTab === 2 ? "text-[#031B59]" : "text-[#A1A1A1]"
              }`}
              onClick={() => setAllTab(2)}
            >
              {t("myFeedbacks")}
            </div>
          </div>
        </div>
      </div>

      {/* {reload ? (
        <LoaderComp />
      ) : ( */}
      <div
        className="w-full rounded-[8px] flex flex-col gap-4 items-center
        custom_scroll overflow-x-hidden h-[65vh]"
      >
        {feedbackData.length > 0 ? (
          feedbackData.map((obj, index) => (
            <div
              key={index}
              className="h-fit border rounded-[8px] flex flex-col p-4 mx-4"
            >
              <div className="flex items-center justify-between ">
                <div className="flex items-center gap-2">
                  <div>
                    <ProfileAvtar
                      height="2rem"
                      width="2rem"
                      name={obj.full_name ? obj.full_name : "User"}
                    />
                  </div>
                  <div className="text-[#031B59] w-full font-medium ">
                    {obj.full_name}
                  </div>
                </div>
                <div className="text-[#031B59]">{obj.created_at}</div>
              </div>
              <div className="text-[#A1A1A1]">{obj.comment}</div>
            </div>
          ))
        ) : (
          <div className="text-black relative z-10 flex justify-center items-center h-[10rem] left-[5px]">
            {t("Nofeedbacksavailable")}
          </div>
        )}
      </div>
      {/* // )} */}
    </>
  );
};

export default FeedbackEventDetails;