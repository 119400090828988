
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import ToastServices from "ToastServices";

const AuthGuard = ({ Component, roleList, name }) => {
  const navigate = useNavigate();
  const userData = localStorage.getItem("userLoginToken")
    ? JSON.parse(localStorage.getItem("userLoginToken"))
    : "";

  const tokenExpiryTime = localStorage.getItem("tokenExpiryTime");
  const role = userData?.role;

  useEffect(() => {
    if (userData && tokenExpiryTime) {
      const currentTime = Date.now();
      const expiryTime = parseInt(tokenExpiryTime, 10);

      if (currentTime < expiryTime) {
        const timeUntilExpiry = expiryTime - currentTime;
        setTimeout(() => {
          ToastServices.showToast({
            message: "Please Login Again. Your Session Has Expired !",
            type: "error",
            autoClose: 3000,
          });
          localStorage.removeItem("userLoginToken");
          localStorage.removeItem("tokenExpiryTime");
          navigate("/login");
        }, timeUntilExpiry);
      } else {
        ToastServices.showToast({
          message: "Please Login Again. Your Session Has Expired !",
          type: "error",
          autoClose: 3000,
        });
        localStorage.removeItem("userLoginToken");
          localStorage.removeItem("tokenExpiryTime");
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [userData, tokenExpiryTime]);

  // if (name === "Dashboard") {
  //   return (
  //     <>
  //       <meta name="robots" content="noindex" />
  //       <Component role={role} />
  //     </>
  //   );
  // }

  if (!roleList) {
    return <Component />;
  }
  const ComponentRendered = Component[role];

  return roleList?.includes(role) ? <ComponentRendered /> : navigate("/404");
};

export default AuthGuard;

AuthGuard.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  roleList: PropTypes.arrayOf(Object),
  name: PropTypes.string,
};
