import React, { useEffect, useState } from "react";
import { SlCloudUpload } from "react-icons/sl";
import DatePicker from "component/common/DatePicker";
import LoaderComp from "component/loader/LoaderComp";
import { Form, FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { postCandidateEvents } from "reduxToolkit/slices/calender";
import { formatTime } from "utils/date";
import { handleSearchUserForChat } from "redux/appThunk/Employee/chat";
import useDebounce from "hooks/useDebounce";

const CandidateInterviewPopUp = ({
  jobTitle,
  job,
  filterEvent,
  toggleAddEventModal,
  selectedOption,
}) => {
  const { t } = useTranslation();
  const [guestName, setGuestName] = useState("");
  const [formData, setFormData] = useState([]);
  const [guestTypeEmail, setGuestTypeEmail] = useState("");
  const [formDataId, setFormDataId] = useState([]);
  const [loder, setLoder] = useState(false);
  let searchQuery = useDebounce(guestName, 300);
  const dispatch = useDispatch();
  const formattedEndTime = formatTime(filterEvent?.endTime);
  const formattedStartTime = formatTime(filterEvent?.startTime);

  useEffect(() => {
    if (formData) {
      const formDataNew = formData?.find((item) => item.id);
      setFormDataId(formDataNew);
    }
  }, [formData]);

  const handleRemoveImage = () => {
    setFieldValue(`Upload_Image`, "");
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      setFieldValue("Upload_Image", files[0]);
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Please add Title"),
    date: Yup.string().required("Invalid date format"),
    startTime: Yup.string().required("Please add start time"),
    endTime: Yup.string()
      .required("Please add end time")
      .test(
        "is-greater",
        "End time should be greater than start time",
        function (value) {
          const { startTime } = this.parent;
          return startTime && value > startTime;
        }
      ),
    round: Yup.string().required("Please add rounds"),
  });

  const handleClose = () => {
    toggleAddEventModal();
  };

  useEffect(() => {
    if (guestName !== "") {
      dispatch(handleSearchUserForChat(searchQuery));
    }
  }, [searchQuery]);

  useEffect(() => {
    if (formData) {
      const formDataNew = formData?.map((item) => item.id);
      setFormDataId(formDataNew);
    }
  }, [formData]);

  useEffect(() => {
    if (filterEvent) {
      setGuestTypeEmail(
        filterEvent ? filterEvent?.guestEmails?.join(", ") : ""
      );
    }
  }, [filterEvent]);

  const Formik = useFormik({
    initialValues: {
      candidateName: "",
      title: jobTitle || "",
      date: filterEvent
        ? new Date(filterEvent.date).toISOString().split("T")[0]
        : "",
      startTime: formattedStartTime || "",
      endTime: formattedEndTime || "",
      round: filterEvent?.round || "",
      description: filterEvent?.desc || "",
      budget: "",
      resume: job?.resumeUrl?.name || "",
      Upload_Image: "",
      guests: "",
    },
    validationSchema,
    enableReinitialize: true,

    onSubmit: async (values, { resetForm }) => {
      const newFile = new File(["foo"], job?.resumeUrl?.name, {
        type: job?.resumeUrl?.type,
      });
      const newForm = new FormData();
      if (filterEvent) {
        const json = {
          event: {
            event_type: "",
            title: values.title,
            date: values.date,
            start_time: values.startTime,
            end_time: values.endTime,
            description: values.description,
            guests_ids: formDataId,
            budget: values.budget,
            event_documents_urls: newFile,
          },
        };
        // need when update
        // dispatch(updateEvent(filterEvent?.id, json, setLoder, setIsopen, setAgainData));
      } else {
        newForm.append("event[event_type]", selectedOption);
        newForm.append("event[candidate_name]", values.candidateName);
        newForm.append("event[title]", values.title);
        newForm.append("event[date]", values.date);
        newForm.append("event[start_time]", values.startTime);
        newForm.append("event[end_time]", values.endTime);
        newForm.append("event[description]", values.description);
        newForm.append("event[guest_emails]", values.guests);
        newForm.append("event[round]", values.round);
        newForm.append("event[event_documents]", values.Upload_Image);
        try {
          await dispatch(postCandidateEvents(newForm, handleClose, resetForm));
        } catch (error) {
          console.error("API Error:", error);
        }
      }
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
  } = Formik;

  return (
    <div className="p-4 pt-0 rounded-md">
      <FormikProvider value={Formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className="space-y-4 overflow-y-auto custom_scroll"
        >
          <div className="flex flex-col gap-5">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("candidateName")}
              </label>
              <input
                type="text"
                name="candidateName"
                value={values.candidateName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("candidateName")}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("title")}
              </label>
              <input
                type="text"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Placeholder"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
              {errors.title && touched.title && (
                <p className="text-[red] w-80">{errors.title}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("date")}
              </label>
              <DatePicker
                name="date"
                value={values.date}
                handleChange={handleChange}
                onBlur={handleBlur}
                min={new Date().toISOString().split("T")[0]}
                className="h-[2.625rem] w-full"
              />
              {errors.date && touched.date && (
                <p className="text-[red] w-80">{errors.date}</p>
              )}
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {t("startTime")}
                </label>
                <input
                  name="startTime"
                  type="time"
                  value={values.startTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
                {errors.startTime && touched.startTime && (
                  <p className="text-[red] w-80">{errors.startTime}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {t("endTime")}
                </label>
                <input
                  name="endTime"
                  type="time"
                  value={values.endTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
                />
                {errors.endTime && touched.endTime && (
                  <p className="text-[red] w-80">{errors.endTime}</p>
                )}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("desc")}
              </label>
              <textarea
                name="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={4}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
              {errors.description && touched.description && (
                <p className="text-[red] w-80">{errors.description}</p>
              )}
            </div>  
              <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("addGuest")}
              </label>
              <textarea
                name="guests"
                value={values.guests}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
              {errors.guests && touched.guests && (
                <p className="text-[red] w-80">{errors.guests}</p>
              )}
            </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("round")}
              </label>
              <input
                type="text"
                name="round"
                value={values.round}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Placeholder"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
              {errors.round && touched.round && (
                <p className="text-[red] w-80">{errors.round}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {t("resume")}
              </label>
              {!values.Upload_Image ? (
                <div className="flex flex-col border border-gray-300 rounded-md p-4 items-center mt-1 cursor-pointer">
                  <label
                    htmlFor="upload"
                    className="flex flex-col items-center cursor-pointer"
                  >
                    <SlCloudUpload />
                  </label>
                  <input
                    id="upload"
                    type="file"
                    name="Upload_Image"
                    onChange={handleImageChange}
                    onBlur={handleBlur}
                    className="hidden"
                  />
                </div>
              ) : (
                <div className="flex justify-between items-center mt-1 border border-gray-300 rounded-md p-4">
                  <p className="text-sm">{values.Upload_Image.name}</p>
                  <button
                    type="button"
                    onClick={handleRemoveImage}
                    className="text-red-500"
                  >
                    {t("remove")}
                  </button>
                </div>
              )}
            </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="mt-4 px-9 py-3 rounded-[2.5rem] bg-[#031B59] text-white  w-fit"
            >
              {loder ? <LoaderComp /> : t("schedule")}
            </button>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default CandidateInterviewPopUp;
