import React, { useRef } from "react";
import ProfileMenu from "./ProfileMenu";
import { AiOutlineRight } from "react-icons/ai";
import PropTypes from "prop-types";
import ProfileAvtar from "component/common/ProfileAvtar";

export default function ProfileButon({ onClick, isHidden }) {
  const profileMenuRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));
  const full_name = userData?.full_name;

  return (
    <div
      className="relative w-full cursor-pointer h-full px-1 gap-0 flex items-center justify-center"
      onClick={onClick}
      ref={profileMenuRef}
    >
      <div
        className={`basis-1/2 w-full h-full pt[3px] flex justify-center items-center gap-4 `}
      >
        <ProfileAvtar
          src={userData?.profile_picture}
          name={full_name ? full_name : "User"}
        />
      </div>
      {isHidden !== "hidden" && <ProfileMenu />}
      <div className={`w-full h-full flex items-center gap-4`}>
        <p
          className={`w-full flex whitespace-nowrap delay-100 transition-colors ease-in-out `}
        >
          {full_name ? full_name.slice(0,15) : "User"}
        </p>
        <div className="basis-1/5 w-fit ityems-center justify-end">
          <AiOutlineRight
            className={`${
              isHidden
                ? " rotate-0 delay-150 duration-300 ease-linear"
                : "-rotate-90 delay-150 duration-300 ease-linear"
            }`}
          />
        </div>
      </div>
    </div>
  );
}

ProfileButon.propTypes = {
  onClick: PropTypes.func,
  isHidden: PropTypes.string,
};
