import React from 'react'
import DashboardCount from './DashboardCount'
import DashboardGraphOpening from './DashboardGraphOpening'
import DashboardHeader from './DashboardHeader'
import DashboardOnboard from './DashboardOnboard'
import DashboardNewApplication from './DashboardNewApplication'
import DashboardCalanderTask from './DashboardCalanderTask'

const DashboardAdmin = () => {
  
  return (
    <div className='w-full h-full'>
      <DashboardHeader />
      <div className='flex flex-wrap w-full px-[1.78rem] gap-[1.62rem]'>
        <div className='w-auto flex flex-col gap-[1.62rem] '>
          <DashboardCount />
          <div className=' w-fit h-full twoDivSize gap-[1.62rem] '>
            <DashboardGraphOpening />
            <div className=' w-full onNew gap-[1.62rem]'>
              <DashboardOnboard />
              <DashboardNewApplication />
            </div>
          </div>
        </div>
        <div className='w-fit'>
          <DashboardCalanderTask />
        </div>
      </div>
    </div>
  )
}

export default DashboardAdmin;
