/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IoClose } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import DatePicker from "component/common/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchUserForInterView } from "redux/appThunk/Employee/chat";
import useDebounce from "hooks/useDebounce";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  ScheduleInterview,
  setEventDataById,
  updateEvent,
} from "reduxToolkit/JobSlice";
import LoaderComp from "component/loader/LoaderComp";
import { useTranslation } from "react-i18next";
import { generateRandomWord, roundOption } from "utils/Constants";

const ScheduleInterviewPopup = ({
  isOpen,
  setIsopen,
  setAgainData,
  onClose,
  job,
  jobName,
  jobTitle,
  setInter,
  setFilterData,
  filterEvent,
}) => {
  const getInitialValues = {
    name: jobName,
    title: jobTitle,
    date: "",
    startTime: "",
    endTime: "",
    round: "",
    description: "",
    url: "",
    resume: job?.resumeUrl?.name,
  };
  const [guestName, setGuestName] = useState("");
  const [inputText, setInputText] = useState("");
  const [formData, setFormData] = useState([]);
  const [showUser, setShowUser] = useState(false);
  const [urlInput, setUrlInput] = useState(false);
  const [loder, setLoder] = useState(false);
  let searchQuery = useDebounce(guestName, 300);
  const { searchResult } = useSelector((state) => state.jobs);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [initialValues, setInitalValue] = useState(getInitialValues);
  const resetFormValues = () => {
    setInitalValue(getInitialValues);
    setFormData([]);
    setGuestName("");
  };

  useEffect(() => {
    if (filterEvent) {
      const formattedDate = filterEvent.date
        ? new Date(filterEvent.date).toISOString().split("T")[0]
        : "";
      setInitalValue({
        name: jobName,
        title: jobTitle,
        date: filterEvent ? formattedDate : "",
        startTime: filterEvent ? filterEvent?.startTime : "",
        endTime: filterEvent ? filterEvent?.endTime : "",
        round: filterEvent ? filterEvent?.round : "",
        description: filterEvent ? filterEvent?.description : "",
        url: filterEvent ? filterEvent?.url : "",
        resume: job?.resumeUrl?.name,
      });
      setFormData(filterEvent?.guestEmails);
    }
  }, [filterEvent]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Please enter your name"),
    email: Yup.string().matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid email address"
    ),
    title: Yup.string().required("Please add Title"),
    date: Yup.string().required("Invalid date format"),
    startTime: Yup.string().required("Please add start time"),
    endTime: Yup.string()
      .required("Please add end time")
      .test(
        "is-greater",
        "End time should be greater than start time",
        function (value) {
          const { startTime } = this.parent;

          return startTime && value > startTime;
        }
      ),
    round: Yup.string().required("Please add rounds"),
    url: Yup.string().required("Please add url"),
  });

  const Formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const dataOfUrl =
        values?.url === "Google Meet" ? generateRandomWord() : inputText;
      const newFile = new File(["foo"], job?.resumeUrl?.name, {
        type: job?.resumeUrl?.type,
      });
      const newForm = new FormData();
      newForm.append("event[title]", values.title);
      newForm.append("event[date]", values.date);
      newForm.append("event[start_time]", values.startTime);
      newForm.append("event[end_time]", values.endTime);
      newForm.append("event[description]", values.description);
      newForm.append(
        "event[guest_emails][]",
        formData?.map((item) => item?.email)
      );
      newForm.append("event[meeting_url]", dataOfUrl);
      newForm.append("event[email]", job?.email);
      newForm.append("event[event_documents]", newFile);
      newForm.append("event[round]", values.round);
      newForm.append("event[event_type]", "candidate_interview");

      if (filterEvent) {
        await dispatch(
          updateEvent(
            filterEvent?.id,
            newForm,
            setLoder,
            setIsopen,
            setAgainData
          )
        );
        await dispatch(setEventDataById(null));
      } else {
        await dispatch(
          ScheduleInterview(newForm, setIsopen, setLoder, setAgainData)
        );
      }
    },
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    resetForm,
  } = Formik;

  const removeGuest = (guestEmail) => {
    const filterNewData = formData?.filter((email) => email !== guestEmail);
    setFormData(filterNewData);
  };

  const handleClose = () => {
    setFilterData(null);
    setInter(false);
    resetFormValues();
    onClose();
    resetForm({});
    dispatch(setEventDataById(null));
  };

  useEffect(() => {
    if (guestName !== "") {
      dispatch(handleSearchUserForInterView(searchQuery, setShowUser));
    }
  }, [searchQuery, dispatch]);

  const selectEmployee = (employee) => {
    const updatedEmployee = {
      ...employee,
      full_name: employee.name,
    };
    delete updatedEmployee.name;
    setFormData([...formData, updatedEmployee]);
    setGuestName("");
  };

  return (
    <>
      {isOpen && (
        <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)]">
          <div
            className="min-w-[40%] w-full max-w-md h-full p-5 bg-white flex flex-col
           space-y-8 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transition-all"
          >
            <div className="flex flex-col items-start p-1 gap-2">
              <div className="w-full flex items-center justify-between">
                <h2 className="font-Roboto text-2xl text-[#031B59] font-extrabold">
                  {t("schedule")}
                </h2>
                <IoClose
                  onClick={handleClose}
                  className="fill-[#686868] w-6 h-6 cursor-pointer"
                />
              </div>
              <hr className="border-t border-gray-300 mt-2 mb-1 w-full" />
              <form
                className="w-full pb-3 px-2 space-y-2 h-[90vh]
              overflow-y-auto custom_scroll"
                onSubmit={handleSubmit}
              >
                <div>
                  <label className="block text-[#313135]">{t("name")}</label>
                  <input
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full p-2 border capitalize"
                    type="text"
                  />
                  {errors.name && touched.name ? (
                    <p className="text-[red] w-80">{errors.name}</p>
                  ) : null}
                </div>
                <div>
                  <label className="block text-[#313135]">{t("title")}</label>
                  <input
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="w-full p-2 border capitalize"
                    type="text"
                  />
                  {errors.title && touched.title ? (
                    <p className="text-[red] w-80">{errors.title}</p>
                  ) : null}
                </div>
                <div>
                  <label className="block text-[#313135]">{t("date")}</label>
                  <DatePicker
                    name="date"
                    value={values?.date}
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    min={new Date().toISOString().split("T")[0]}
                    className="h-[2.625rem] w-full"
                  />
                  {errors.date && touched.date ? (
                    <p className="text-[red] w-80">{errors.date}</p>
                  ) : null}
                </div>
                <div className="flex space-x-2">
                  <div>
                    <label className=" text-[#313135]">{t("startTime")}</label>
                    <input
                      name="startTime"
                      value={values.startTime}
                      onChange={handleChange}
                      className="w-full p-2 border"
                      type="time"
                    />
                    {errors.startTime && touched.startTime ? (
                      <p className="text-[red] w-80">{errors.startTime}</p>
                    ) : null}
                  </div>
                  <div>
                    <label className=" text-[#313135]">{t("endTime")}</label>
                    <input
                      name="endTime"
                      value={values.endTime}
                      onChange={handleChange}
                      className="w-full p-2 border"
                      type="time"
                    />
                    {errors.endTime && touched.endTime ? (
                      <p className="text-[red] w-80">{errors.endTime}</p>
                    ) : null}
                  </div>
                </div>
                <div>
                  <label className="block text-[#313135]">
                    {t("description")}
                  </label>
                  <textarea
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    className="w-full p-2 border"
                    rows="4"
                  ></textarea>
                </div>
                <div className="w-full">
                  <label className="block text-[#313135]">
                    {t("addGuest")}
                  </label>
                  <div className="w-auto ">
                    <div className="w-full flex flex-wrap gap-2">
                      {Array.isArray(formData) &&
                        formData?.map((guestEmail, index) => (
                          <div
                            key={index}
                            className="flex items-center w-fit gap-3 bg-[#031b59]
                         text-white p-1 rounded-md"
                          >
                            <span className="break-all">
                              {guestEmail?.full_name
                                ? guestEmail?.full_name
                                : guestEmail?.email}
                            </span>
                            <AiOutlineClose
                              className="cursor-pointer"
                              onClick={() => removeGuest(guestEmail)}
                            />
                          </div>
                        ))}
                      <input
                        value={guestName}
                        className=" flex-1 p-2 border"
                        type="text"
                        placeholder="Add guest"
                        onChange={(e) => setGuestName(e.target.value)}
                      />
                    </div>
                  </div>
                  {guestName !== "" && (
                    <>
                      {showUser ? (
                        <div
                          className="absolute flex justify-start flex-col
                       w-full max-w-[36rem] bg-white gap-3
                       shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-[8px] rounded-md z-10 overflow-y-auto max-h-80"
                        >
                          {searchResult.length > 0 ? (
                            searchResult.map((employee) => (
                              <div
                                key={employee.id}
                                onClick={() => selectEmployee(employee)}
                                className="flex items-center
                           text-black h-[3rem] px-2 cursor-pointer hover:bg-gray-100"
                              >
                                <img
                                  src={`https://ui-avatars.com/api/?name=${employee.name}&background=random`}
                                  alt="Profile Avatar"
                                  className="w-8 h-8 rounded-full mr-2"
                                />

                                <p className="break-all">{employee.name}</p>
                              </div>
                            ))
                          ) : (
                            <div> {t("noDataFound")}</div>
                          )}
                        </div>
                      ) : (
                        <div
                          className="absolute flex justify-start flex-col
                        w-full max-w-[36rem] bg-[#e0ecff] gap-3 break-all
                        shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-[8px] rounded-md z-10 overflow-y-auto max-h-80 text-[#031b59]"
                          onClick={() => selectEmployee({ email: guestName })}
                        >
                          {guestName}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div>
                  <label className="block text-[#313135]">{t("round")}</label>
                  <select
                    className="w-full p-2 cursor-pointer bg-white border"
                    name="round"
                    value={values.round}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="">{t("selectRound")}</option>
                    {roundOption?.map((item) => (
                      <option className="m-2" value={item?.round}>
                        {item?.round}
                      </option>
                    ))}
                  </select>
                  {errors.round && touched.round ? (
                    <p className="text-[red] w-80">{errors.round}</p>
                  ) : null}
                </div>
                <div>
                  <label className="block text-[#313135]">
                    {t("platform")}
                  </label>
                  <select
                    className="w-full p-2 cursor-pointer bg-white border"
                    name="url"
                    value={values.url}
                    onChange={(e) => {
                      handleChange(e);
                      const selectedPlatform = e.target.value;
                      if (selectedPlatform === "Google Meet") {
                        setInitalValue({
                          ...values,
                          url: "Google Meet",
                        });
                        setUrlInput(false);
                      } else if (selectedPlatform === "Other") {
                        setUrlInput(true);
                        setInitalValue({
                          ...values,
                          url: "Other",
                        });
                      } else {
                        setInitalValue({
                          ...values,
                          url: "",
                        });
                        setUrlInput(false);
                      }
                    }}
                    onBlur={handleBlur}
                  >
                    <option value="">{t("selectPlatform")}</option>
                    <option className="m-2" value="Google Meet">
                      {t("googleMeet")}
                    </option>
                    <option className="m-2" value="Other">
                      {t("other")}
                    </option>
                  </select>

                  {urlInput && (
                    <input
                      value={inputText}
                      onChange={(e) => setInputText(e.target.value)}
                      className="w-full p-2 border mt-3"
                      type="url"
                      placeholder="Enter the meeting URL"
                    />
                  )}
                  {errors.url && touched.url ? (
                    <p className="text-[red] w-80">{errors.url}</p>
                  ) : null}
                </div>
                <div>
                  <label className="block text-[#313135]">{t("resume")}</label>
                  <input
                    name="resume"
                    value={values?.resume}
                    className="w-full p-2 border"
                    disabled
                  />
                </div>
                <div className="flex justify-end items-center pt-3">
                  <div
                    onClick={handleClose}
                    className="px-6 py-3 text-lg rounded-md flex items-center border-2 mr-3"
                  >
                    {t("cancel")}
                  </div>
                  <div>
                    {loder ? (
                      <LoaderComp />
                    ) : (
                      <button
                        type="submit"
                        className="px-6 py-3 bg-[#031B59]
                      text-white text-lg rounded-md flex items-center"
                      >
                        {t("schedule")}
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScheduleInterviewPopup;

ScheduleInterviewPopup.propTypes = {
  isOpen: PropTypes.any,
  setIsopen: PropTypes.func.isRequired,
  setAgainData: PropTypes.func.isRequired,
  job: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  jobName: PropTypes.any,
  jobTitle: PropTypes.any,
  filterEvent: PropTypes.any,
};
