import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { searchCalenderEvent } from "redux/appThunk/Employee/chat";
import { SlCalender } from "react-icons/sl";

function SearchCalender({
  searchOpen,
  setSearchOpen,
  searchItem,
  setSearchItem,
  searchRef,
  handleInnerMouseClick,
}) {
  const dispatch = useDispatch();
  const { SearchCalenderEvent } = useSelector((state) => state.jobs);
  const [searchItemCele, setSearchItemCele] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [searchItem, searchOpen]);

  useEffect(() => {
    dispatch(searchCalenderEvent(searchItem));
  }, [searchItem, dispatch]);

  const calendarData = {
    name: "John Doi",
    email: "deeporion@gmail.com",
    title: "Meeting Title",
    date: "Thu Oct 21, 2024",
    time: "5:00 PM-6:30 PM",
  };

  return (
    <>
      {searchOpen ? (
        <div
          ref={searchRef}
          className="lg:w-[60rem] h-[3rem] sm:w-[25rem] sm:justify-center sm:items-center md:w-[30rem] md:justify-center md:items-center flex lg:justify-center absolute lg:top-9 sm:top-8 right-6 lg:items-center z-50"
          onClick={handleInnerMouseClick}
        >
          <input
            type="text"
            placeholder="Search"
            className="w-full h-[100%] border-[#DEE4EB] border-2 rounded-[100px] px-4"
            value={searchItem}
            onChange={(e) => setSearchItem(e.target.value)}
          />
          <div className="flex absolute left-0 top-[3.1rem]">
            {!searchItemCele && (
              <div className="h-[4.2rem] rounded-md lg:w-[60rem] sm:w-[30rem] bg-[#F2F6FF] shadow-md gap-x-3 flex items-center justify-center py-3 px-5">
                <div className="flex justify-center items-center">
                  <SlCalender size={25} color="#031B59" />
                </div>
                <div className="flex text-md w-full justify-center flex-col h-[4rem] font-normal">
                  <div className="flex justify-between">
                    <p className="text-base">
                      <span>{calendarData.name} : </span> {calendarData.title}
                    </p>
                    <p className="text-sm text-[#A1A1A1]">{calendarData.date}</p>
                  </div>
                  <div className="flex justify-between text-[#A1A1A1]">
                    <p className="text-sm">{calendarData.email}</p>
                    <p className="text-sm text-[#A1A1A1]">{calendarData.time}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <button
            className="right-4 absolute"
            onClick={() => setSearchOpen(false)}
          >
            <IoClose className="w-[1rem] h-[1rem]" size={35} />
          </button>
        </div>
      ) : (
        <button
          onClick={() => setSearchOpen(true)}
          className="rounded-[100px] w-[42px] h-[42px] flex justify-center items-center p-1 border-2 border-[#DEE4EB]"
        >
          <FiSearch size={27} className="text-[#031B59]" />
        </button>
      )}
    </>
  );
}

export default SearchCalender;