import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdPadding } from "react-icons/md";
import { t } from "i18next";
import JobsForm from "./JobsForm";
import { IoLocation } from "react-icons/io5";
import { PiTargetBold } from "react-icons/pi";
import { BsBriefcase } from "react-icons/bs";
import LoaderComp from "component/loader/LoaderComp";
import { EditButton } from "component/common/accessControlUi/Button";
import { mappedPermissionObj } from "hooks/usePermission";
import { fetchJobOpeningDataById } from "reduxToolkit/JobSlice";
import { RiArrowRightDoubleLine } from "react-icons/ri";

export default function JobOpeningDetails() {
  const { isLoading, individualOpening, indiviualStatus } = useSelector(
    (state) => state.jobs
  );
  const navigate = useNavigate();
  const [editId, setEditId] = useState(null);
  const [editPopUp, setEditPopUp] = useState(false);
  const [submit, setSubmit] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();

  const handleJobHeader = () => {
    navigate('/opening/currentOpening')
  }

  useEffect(() => {
    if (id) {
      dispatch(fetchJobOpeningDataById(id));
    }
  }, [id, submit]);

  useEffect(() => {
    if (indiviualStatus === "success") {
      setEditId(null);
      setEditPopUp(false);
    }
  }, [indiviualStatus]);

  return (
    <>
      <div className="flex w-full items-center py-[1.5rem] px-[1.5rem]">
        <Link to="/opening/currentOpening" onClick={handleJobHeader}>
          <h3 className="font-normal text-[#191919] text-[1.5rem] flex items-center">
            {t("currentOpening")}
            <span className="text-[#A1A1A1] mx-2">
              <RiArrowRightDoubleLine />
            </span>
          </h3>
        </Link>
        <div className="capitalize font-bold text-[1.5rem] text-[#031b59]">
          {individualOpening?.title}
        </div>
      </div>
      <div
        className="w-[96%] h-[90vh] bg-white space-y-4 flex flex-col
      p-5 rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] overflow-y-auto"
      >
        {editPopUp && (
          <JobsForm
            setAddJobOpeningPop={setEditPopUp}
            id={editId}
            setSubmit={setSubmit}
          />
        )}
        {isLoading ? (
          <LoaderComp />
      ) : (
          <>
            <div className="w-full h-16 bg-white flex justify-between">
              <div className="flex justify-center items-center gap-2">
                <div className="w-[45px] h-[45px] flex items-center justify-center bg-[#ff79140d] rounded-full m-2">
                  <BsBriefcase className="fill-[#ED6E0F] size-6" />
                </div>
                <div className="flex flex-col">
                  <p className="text-[#031B59] text-[20px] font-bold capitalize">
                    {individualOpening?.title}
                  </p>
                  <p className="w-full flex text-[#686868] text-[16px] capitalize"> {individualOpening?.jobType}</p>
                </div>
              </div>

              <div className="flex items-center justify-center space-x-4">
                <button
                  className="border border-[#E2E8F0] rounded-full w-[49px] h-[49px] flex
              items-center justify-center"
                >
                  <EditButton
                    currentResource={mappedPermissionObj.Jobopening}
                    onClick={() => {
                      setEditId(id);
                      setEditPopUp(true);
                    }}
                  />
                </button>
              </div>
            </div>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-row space-x-2 items-center">
                <IoLocation color="ED6E0F" fontSize="20px" />
                <p className="text-[#686868] capitalize">
                  {individualOpening.location}
                </p>
                &nbsp;
              </div>
              <div className="flex flex-row space-x-2 items-center">
                <PiTargetBold color="ED6E0F" fontSize="20px" />
                <p className="text-[#686868] capitalize">
                  {individualOpening?.requirements}
                  <span>
                    {parseInt(individualOpening?.requirements) > 1
                      ? t("years")
                      : t("year")}
                  </span>
                </p>
                &nbsp;
              </div>
              <div className="flex flex-row space-x-2 items-center">
                <MdPadding color="ED6E0F" fontSize="20px" />
                <p className="text-[#686868]">
                  {individualOpening.vacancy}{" "}
                  <span>
                    {parseInt(individualOpening.vacancy) > 1
                      ? t("openings")
                      : t("opening")}
                  </span>
                </p>
                &nbsp;
              </div>
            </div>
            <div className="flex flex-col space-y-4 mt-7">
              <span className="text-[#031B59] text-[20px] font-bold">
                {t("aboutJob")}
              </span>
              <div
                className="text-[#686868] job-description "
                dangerouslySetInnerHTML={{
                  __html: individualOpening.description,
                }}
              ></div>
            </div>
            <div className="flex flex-col space-y-4 mt-7">
              <span className="text-[#031B59] text-[20px] font-bold">
                {t("benefitsofworking")}
              </span>
              <div className="flex flex-row items-center gap-5">
                {individualOpening?.benefits?.map((input, index) => (
                  <div
                    key={index}
                    className="flex flex-row
                          items-center bg-[#E7F2E8] text-[#686868]
                          h-[32px] border border-[#1A8718] p-2 rounded-md"
                  >
                    <h6 className="min-w-[5rem] bg-[#E7F2E8] border-none outline-none">
                      {input}
                    </h6>
                    <div className="cursor-pointer"></div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
