import React from "react";

const Tab = ({ title, isActive, onClick }) => (
  <h1
    className={` cursor-pointer ${isActive ? "text-[#031b59]" : "text-[#A1A1A1]"}`}
    onClick={onClick}
  >
    {title}
  </h1>
);

export default Tab;
