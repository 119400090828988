import React from "react";
import { useNavigate } from "react-router-dom";
import { content } from "../../utils/constant/Content";
import { FiUser, FiSettings } from "react-icons/fi";
import { MdPowerSettingsNew } from "react-icons/md";
import { GoVersions } from "react-icons/go";
import { useDispatch } from "react-redux";
import { handleUpdateStatus } from "redux/appThunk/Employee/chat";
import ToastServices from "ToastServices";

export default function ProfileMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userLoginToken"));

  const handlelogOut = async () => {
    dispatch(handleUpdateStatus(false));
    localStorage.clear();
    ToastServices.showToast({
      message: "Logout Successfully!",
      type: "success",
      autoClose: 3000,
    });
    navigate("/login");
  };

  return (
    <nav
      className={`w-[90%] h-fit absolute right-[0.4rem] bottom-[2.9rem]
        text-white shadow-[3px_2px_8px_5px_rgb(3,27,89,0.1)] bg-[#3D5492]
        transition-colors delay-120 ease-linear rounded-md `}
    >
      <ul className="w-full p-2 flex flex-col items-center ">
        {/* <li className="w-full h-[2.438rem] flex justify-start hover:bg-white/10 items-center bg-[#3D5492] rounded">
          <button
            className={`w-full h-full p-3 flex justify-start items-center gap-4`}
            onClick={() => {
              navigate(`/employees/profilebasics/${userData.id}`);
            }}
          >
            <FiUser className={`h-[1.25rem] w-[1.25rem]`} />
            <p
              className={`flex justify-start items-center hover-blue-800 w-full h-full`}
            >
              {content.myprofile}
            </p>
          </button>
        </li>

        {userData?.role === "admin" && (
          <li className="w-full h-[2.438rem] flex justify-start hover:bg-white/10 items-center bg-[#3D5492] rounded">
            <button
              className={`w-full h-full p-3 flex justify-start items-center gap-4`}
              onClick={() => {
                navigate("/companyProfile");
              }}
            >
              <FiUser className={`h-[1.25rem] w-[1.25rem]`} />
              <p
                className={`flex justify-start items-center hover-blue-800 w-full h-full`}
              >
                {content.Companyprofile}
              </p>
            </button>
          </li>
        )}
        {userData?.role === "admin" && (
          <li
            className={`w-full h-[2.438rem] hover:bg-white/10 items-center rounded bg-[#3D5492]`}
          >
            <button
              className={`w-full h-full p-3 gap-4 flex justify-start items-center `}
              onClick={() => {
                navigate("/settings");
              }}
            >
              <FiSettings className={`h-[1.25rem] w-[1.25rem]`} />
              <p
                className={`flex justify-start items-center hover-blue-800 w-full h-full`}
              >
                {content.settings}
              </p>
            </button>
          </li>
        )}
        {userData?.role === "admin" && (
          <li
            className={`w-full h-[2.438rem] hover:bg-white/10 items-center bg-[#3D5492]`}
          >
            <button
              className={`w-full h-full p-3 gap-4 flex justify-start items-center rounded`}
              onClick={() => {
                navigate("/version");
              }}
            >
              <GoVersions className={`h-[1.25rem] w-[1.25rem]`} />
              <p
                className={`flex justify-start items-center hover-blue-800 w-full h-full`}
              >
                Version
              </p>
            </button>
          </li>
        )} */}
        <li className="w-full h-[2.438rem] flex justify-start hover:bg-white/10 items-center bg-[#3D5492]">
          <button
            title="Logout"
            className={`w-full h-full p-3 space-x-4 flex justify-start items-center bg-[#031B59] rounded`}
            onClick={handlelogOut}
          >
            <MdPowerSettingsNew className={`h-[1.25rem] w-[1.25rem]`} />
            <p
              className={`flex justify-start items-center hover-blue-800 w-full h-full`}
            >
              {content.logout}
            </p>
          </button>
        </li>
      </ul>
    </nav>
  );
}
